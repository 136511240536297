import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import axios from "axios";
import UserStore from "../../store/UserStore";
import { BaseURL } from "../../utils/baseURL";
import { usePersistentStore } from "../../store";
const HelpModal = ({ closeHelpModal }) => {
  const [descriptionProblem, setDescriptionProblem] = useState("");
  const [selectedTheme, setSelectedTheme] = useState(true);
  const { UserStore } = usePersistentStore();
 function sendQuestion() {

   const body = {
     status: "AWAIT",
     content: descriptionProblem,
     theme: "медленная загрузка сайта",
   };
   axios
     .post(`${BaseURL}/v1/create_ticket/`, body, UserStore.getConfig())
     .then((res) => {
       if (res.status < 400) {
         closeHelpModal();
       }
     })
     .catch((err) => {
       console.log("🚀 ~ file: MuiTableItem.jsx:19 ~ .then ~ err:", err);
     });
 };

  function onConfirm() {
    sendQuestion();
  }

  return (
    <ModalContainer onClick={closeHelpModal}>
      <ModalHelpInner onClick={(e) => e.stopPropagation()}>
        <Title>Помощь</Title>
        <FormContent>
          <ThemeSelector
            value={selectedTheme}
            onChange={(e) => {
              setSelectedTheme(`${e.target.value}`);
            }}
          >
            <ChooseTheme value="Выберите тему  обращения">
              Выберите тему обращения
            </ChooseTheme>
            <ChooseTheme value={"Художник еблан"}>Художник еблан</ChooseTheme>
          </ThemeSelector>
          <DescriptionProblem
            placeholder="Опишите что произошло"
            value={descriptionProblem}
            onChange={(e) => setDescriptionProblem(e.target.value)}
          />
          <ConfirmBtn onClick={onConfirm}>Отправить</ConfirmBtn>
          <CloseBtn onClick={closeHelpModal}>Закрыть</CloseBtn>
        </FormContent>
      </ModalHelpInner>
    </ModalContainer>
  );
};

export default HelpModal;

const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
`;

const ModalHelpInner = styled.div`
  min-width: 464px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: 24px 32px 42px;
  background-color: #fff;
`;

const Title = styled.div`
  color: #353535;
  text-align: center;
  font-family: Arsenal;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const FormContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ThemeSelector = styled.select`
  border: 1px solid #b3b3b3;
  padding: 18px 12px;
  color: #b3b3b3;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  &:focus {
    border: 1px solid #b3b3b3;
  }
`;

const ChooseTheme = styled.option`
  border: 1px solid #b3b3b3;
  padding: 18px 12px;
  color: #b3b3b3;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const DescriptionProblem = styled.textarea`
  border: 1px solid #b3b3b3;
  color: #b3b3b3;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 18px 12px;
  resize: none;
  min-height:200px;
`;

const ConfirmBtn = styled.button`
  color: #fff;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: #353535;
  padding: 18px 12px;
  cursor: pointer;
`;
const CloseBtn = styled.button`
  background-color: #fff;
  color: #353535;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 1px solid #353535;
  padding: 18px 12px;
  cursor: pointer;
`;
