import React, { useEffect, useState } from "react";
import Breadcrumbs from "../Breadcrumbs";
import "../../Styles/StoreCardPage.css";
import BuyImg from "../../image/Store-buy.png";
import Calendar from "../../image/Calendar.svg";
import Graph from "../../image/Graph.png";
import iconHeart from "../../image/iconHeart.svg";
import ArtistsPhoto from "../../image/artists.png";
import Aplus from "../../image/Aplus.svg";
import Achievement from "../../image/achievement.png";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { BaseURL } from "../../utils/baseURL";
import { usePersistentStore } from "../../store";

const StoreCardPage = () => {
  const { DetailedArtStore } = usePersistentStore();
  const [openModal, setOpenModal] = useState(false);
  const [pictures, setPictures] = useState(DetailedArtStore.getImages());
  const [selectedPicture, setSelectedPicture] = useState(
    DetailedArtStore.getImages()[0]
  );

  return (
    <div className="wrapper-store-card-page">
      <div className="container-store-card-page">
        <Breadcrumbs />
        <div className="title-container-store-card-page">
          <div className="title-store-card-page">
            {DetailedArtStore.getTitle()}
          </div>
          <div className="title-sub-store-card-page">
            {DetailedArtStore.getAuhor()}
          </div>
          
        </div>

        <div className="buy-container-store-card-page">
          <div className="buy-image-container">
            <img src={selectedPicture} alt="" />
            <div className="buy-image-sub">
              {pictures.map((e, i) => (
                <img
                  src={e}
                  alt=""
                  key={i}
                  onClick={() => setSelectedPicture(e)}
                />
              ))}
            </div>
          </div>

          <div className="buy-text-container">
            <div className="buy-text-title">Провенанс</div>
            <div className="buy-text-content">
              {DetailedArtStore.getDescription() ==="" ? "" : `Описание: ${DetailedArtStore.getDescription()}`}
            </div>
            <div className="buy-text-content">
              {DetailedArtStore.getArtist() === "" ? "" : `Автор картины: ${DetailedArtStore.getArtist()}`} 
            </div>
            <div className="buy-text-content">
              {DetailedArtStore.getYear() === "" ? "" : `Год создания: ${DetailedArtStore.getYear()} г.`} 
            </div>
            <div className="buy-text-content">
              {DetailedArtStore.getSize_w() && DetailedArtStore.getSize_h() !==0 ? `Размер: ${DetailedArtStore.getSize_w()} x ${DetailedArtStore.getSize_h()
              }` : ""}  
            </div>
          </div>

          <div className="buy-button-price-container">
            <div className="buy-button-price-line">
              {DetailedArtStore.getPrice()} Руб.
            </div>
            <div className="buy-button-container">
              <button onClick={() => setOpenModal(!openModal)} id="button-buy">
                Купить
              </button>
              <button id="button-like">
                <img src={iconHeart} alt="" />
              </button>
            </div>
          </div>
        </div>

        {openModal && (
          <div className="modal-window">
            <div className="modal-window-arrow"></div>
            <div className="modal-window-info">
              <span className="modal-window-info-title">
                Покупки на OLDI PICTURE
              </span>
              <span className="modal-window-info-subtitle">
                <Link to="/MainLogin" className="subtitle-link">
                  Войти
                </Link>{" "}
                или{" "}
                <Link to="/Register" className="subtitle-link">
                  Зарегистрироваться
                </Link>{" "}
                чтобы иметь возможность покупать / продавать картины
              </span>
              <div className="modal-window-button">
                <button className="modal-login">
                  <Link to="/MainLogin" className="subtitle-link">
                    Войти
                  </Link>
                </button>
                <button className="modal-register">
                  <Link to="/Register" className="subtitle-link-register">
                    Регистрация
                  </Link>
                </button>
              </div>
            </div>
          </div>
        )}

        {/* <div className="investment">
                    <div className="investment-title">
                        <span className="investment-title-text">Инвестиционная перспектива</span>
                    </div>
                    <div className="investment-price">
                        <div className="investment-price-max">
                            <span className="investment-price-text">Максимальная стоимость</span>
                            <span className="investment-price-button">125 000 руб.</span>
                        </div>
                        <div className="investment-price-max">
                            <span className="investment-price-text">Минимальная стоимость</span>
                            <span className="investment-price-button">0 руб.</span> 
                        </div>
                    </div>
                </div> */}

        {/* <div className="about-container-store-card-page">
                    <div className="about-img-container">
                        <img src={ArtistsPhoto} alt="" id="aboutImg"/>
                        <button className="about-btn">Виктор Пономаренко</button>
                        <img src={Aplus} alt="" id="aplusImg"/>
                    </div>
                    <div className="about-text-container">
                        <div className="buy-text-title">О художнике</div>
                        <div className="buy-text-content about">
                            <p>Женское окончание вразнобой выбирает конкретный речевой акт. Брахикаталектический стих, как бы это ни казалось парадоксальным, сложен. Дактиль начинает речевой акт, хотя в существование или актуальность этого он не верит, а моделирует собственную реальность.</p>
                            <p>Аллюзия, соприкоснувшись в чем-то со своим главным антагонистом в постструктурной поэтике, параллельна. Анжамбеман нивелирует голос персонажа. Обычная литература, перенесенная в Сеть, не является "сетературой" в смысле отдельного жанра, однако амфибрахий вызывает цикл.</p>
                            <p>Познание текста, несмотря на внешние воздействия, самопроизвольно. Различное расположение, несмотря на внешние воздействия, ненаблюдаемо. Однако, исследователи постоянно сталкиваются с тем, что цикл осознаёт акцент. Гиперцитата, без использования формальных признаков поэзии, редуцирует ритм. Стихотворение, соприкоснувшись в чем-то со своим главным антагонистом в постструктурной поэтике, выбирает деструктивный гекзаметр. Графомания дает резкий генезис свободного стиха, таким образом в некоторых случаях образуются рефрены, кольцевые композиции, анафоры.</p>
                            <p>Познание текста, несмотря на внешние воздействия, самопроизвольно. Различное расположение, несмотря </p>
                        </div>
                    </div>
                </div> */}

        {/* <div className="achievements-container-store-card-page">

                    <div className="achievements-title">Достижения художника</div>

                    <div className="achievements-img-container">
                        <div className="achievements-img-box">
                            <img src={Achievement} alt="" />
                            <div className="achievements-box-title">Лучшая рука Дикого Запада</div>
                            <div className="achievements-box-content">Получена 13 сентября 2022</div>
                        </div>
                        <div className="achievements-img-box">
                            <img src={Achievement} alt="" />
                            <div className="achievements-box-title">Лучшая рука Дикого Запада</div>
                            <div className="achievements-box-content">Получена 13 сентября 2022</div>
                        </div>
                        <div className="achievements-img-box">
                            <img src={Achievement} alt="" />
                            <div className="achievements-box-title">Лучшая рука Дикого Запада</div>
                            <div className="achievements-box-content">Получена 13 сентября 2022</div>
                        </div>
                        <div className="achievements-img-box">
                            <img src={Achievement} alt="" />
                            <div className="achievements-box-title">Лучшая рука Дикого Запада</div>
                            <div className="achievements-box-content">Получена 13 сентября 2022</div>
                        </div>
                    </div>

                </div> */}
      </div>
    </div>
  );
};

export default observer(StoreCardPage);
