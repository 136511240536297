import React from "react";
import "../../Styles/Store.css";
// import img from "../../image/auctions-img.png";
import investRating from "../../image/invest_rating.svg";
import LikeBtn from "../../image/favourite-store-art.svg";
import AddBtn from "../../image/add-btn.svg";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

const StoreCard = ({ name, price, firstname, secondname, img }) => {
  const navigate = useNavigate();
  return (
    <div className="store-card" onClick={() => navigate("/store/card")}>
      <div className="store-card-img-container">
        <img className="store-card-img" src={img} />
        {/* <img className="store-invest-rating" src={investRating} /> */}
      </div>
      <div className="store-card-title">{name}</div>
      <div className="store-card-subtitle-line">
        <div className="store-card-subtitle-left">
          <div className="store-card-subtitle">Оригинал картины</div>
          <div className="store-card-author">
            {firstname} {secondname}
          </div>
        </div>
        <img className="store-card-btn" src={LikeBtn} />
      </div>
      <div className="store-card-price-line">
        <div className="store-card-price">{price} руб.</div>
        <img className="store-card-btn" src={AddBtn} />
      </div>
      <div className="store-card-btn-line">
        <img className="store-card-btn" src={LikeBtn} />
        <img className="store-card-btn" src={AddBtn} />
      </div>
      <div className="store-card-info-mobile-container">
        <div className="store-card-title">{name}</div>
        <div className="store-card-subtitle-line">
          <div className="store-card-subtitle-left">
            <div className="store-card-subtitle">Оригинал картины</div>
            <div className="store-card-author">
              {" "}
              {firstname} {secondname}о
            </div>
          </div>
          <img className="store-card-btn" src={LikeBtn} />
        </div>
        <div className="store-card-price-line">
          <div className="store-card-price">{price} руб.</div>
          <img className="store-card-btn" src={AddBtn} />
        </div>
        <div className="store-card-btn-line">
          <img className="store-card-btn" src={LikeBtn} />
          <img className="store-card-btn" src={AddBtn} />
        </div>
      </div>
    </div>
  );
};

export default observer(StoreCard);
