import React from 'react'
import styled from 'styled-components'
import Breadcrumbs from '../Breadcrumbs'
import MyPhoto from '../../image/Artist/MyPhoto.png'
import WorksCollectItem from './WorksCollectItem'
import { observer } from 'mobx-react-lite'
import { usePersistentStore } from "../../store";

const WorksCollect = () => {

  const { ArtistStore } = usePersistentStore();

  return (
    <Wrapper>
      <Container>
        <Breadcrumbs/>
        <MainArtist>
          <ArtistName>{ArtistStore.getName()}</ArtistName>
        </MainArtist>
        <InfoArtist>
          <MainPhoto>
            <Img src={ArtistStore.getAvatar()} alt="Picture"/>
          </MainPhoto>
          <MainInfo>
            <Title>О коллекционере</Title>
            <SubTitle>
              Женское окончание вразнобой выбирает конкретный речевой акт. Брахикаталектический стих, как бы это ни казалось парадоксальным, сложен. Дактиль начинает речевой акт, хотя в существование или актуальность этого он не верит, а моделирует собственную реальность.<br/><br/>

              Ал иллюзия, соприкоснувшись в чем-то со своим главным антагонистом в постструктурной поэтике, параллельна. Анжамбеман нивелирует голос персонажа. Обычная литература, перенесенная в Сеть, не является "сетературой" в смысле отдельного жанра, однако амфибрахий вызывает цикл.<br/><br/>

              Познание текста, несмотря на внешние воздействия, самопроизвольно. Различное расположение, несмотря на внешние воздействия, ненаблюдаемо. Однако, исследователи постоянно сталкиваются с тем, что цикл осознаёт акцент. Гиперцитата, без использования формальных признаков поэзии, редуцирует ритм. Стихотворение, соприкоснувшись в чем-то со своим главным антагонистом в постструктурной поэтике, выбирает деструктивный гекзаметр. Графомания дает резкий генезис свободного стиха, таким образом в некоторых случаях образуются рефрены, кольцевые композиции, анафоры.
            </SubTitle>
          </MainInfo>
        </InfoArtist>
        <Works>
            <TitleWorks>Работы коллекционера</TitleWorks>
            <MyPictures>
            {ArtistStore.getPictures()
              .filter((e) => e.status === "APPROVED")
              .map((e, i) => {
                return (
                  <WorksCollectItem
                    title={e.name}
                    url={e.url}
                    id={e.id}
                    firstname={ArtistStore.getFirstname()}
                    lastname={ArtistStore.getLastname()}
                    pictures={e.pictures}
                    description={e.description}
                    price={e.price}
                    year={e.year}
                    artist={e.artist}
                    size_h = {e.size_h}
                    size_w = {e.size_w}
                  />
                );
              })}
              
              
              
            </MyPictures>
          </Works>
      </Container>
    </Wrapper>
  )
}

export default observer(WorksCollect);

const Wrapper = styled.div`
  font-family: 'Arsenal', sans-serif;
  color: #353535;
  display: flex;
  align-items: center;
  width: 100%;
  
`;
const Container = styled.div`
  margin: auto;
  max-width: 1170px;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: calc(100% - 40px );
    
  }
`;
const MainArtist = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #B3B3B3;
  border-top: 1px solid #B3B3B3;
`;
const ArtistName = styled.span`
  font-size: 32px;
  font-weight: 700;
  line-height: 40.13px;
  padding: 30px 0;
  @media (max-width: 480px) {
    font-size: 26px;
  }
`;
const InfoArtist = styled.div`
  display: flex;
  margin: 50px 0;
  gap: 30px;
  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
`;
const MainPhoto = styled.div`
  
`;
const Img = styled.img`
  width: 370px;
  height: 508px;
  object-fit: cover;
 @media (max-width: 640px) {
    width: 200px;
    height: 270px;
  }
`;
const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 500px;
  @media (max-width: 640px) {
    max-height: 300px;

  }
  @media screen and (max-width: 480px) {
    max-height: unset;
  }
  
`;
const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 22.57px;
  @media (max-width: 480px) {
    text-align: center;
  }
  
`;
const SubTitle = styled.span`
  margin: 0;
  font-size: 16px;
  line-height: 30px;
  
  @media (max-width: 1100px) {
    overflow: auto;
  }
`;
const Works = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 90px;
`;
const TitleWorks = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 30.1px;
  @media (max-width: 480px) {
    text-align: center;
  }
`;
const MyPictures = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  
`;