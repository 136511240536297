import { types } from "mobx-state-tree";


export const DetailedArtStore = types.model({
    title: types.optional(types.string, ""),
    author: types.optional(types.string, ""),
    images: types.optional(types.array(types.string), []),
    picturesIds: types.optional(types.array(types.number), []),
    description: types.optional(types.string, ""),
    price: types.optional(types.number, 0),
    status: types.optional(types.string, ""),
    year: types.maybeNull(types.string),
    artist: types.maybeNull(types.string),
    size_h: types.maybeNull(types.string),
    size_w: types.maybeNull(types.string),
}).actions((self) => ({
    setTitle(title) {
        self.title = title
    },
    setAuthor(firstname, lastname) {
        self.author = `${firstname} ${lastname}`
    },
    setDescription(description) {
        self.description = description
    },
    setImages(images) {
        self.images = images
    },
    setPrice(price) {
        self.price = price
    },
    setPituresIds(ids) {
        self.picturesIds = ids
    },
    setStatus(status) {
        self.status = status
    },
    setYear(year) {
        self.year = year
    },
    setArtist(artist) {
        self.artist = artist
    },
    setSize_h(size_h){
        self.size_h = size_h
    },
    setSize_w(size_w){
        self.size_w = size_w
    },
})).views((self) => ({
    getTitle() {
        return self.title
    },
    getAuhor() {
        return self.author
    },
    getDescription() {
        return self.description
    },
    getImages() {
        return self.images
    },
    getPrice() {
        return self.price
    },
    getPicturesIds() {
        return self.picturesIds
    },
    getStatus() {
        return self.status
    },
    getYear() {
        return self.year === null ? "" : self.year
    },
    getArtist() {
        return self.artist === null ? "" : self.artist
    },
    getSize_h(){
        return self.size_h === null ? "" : self.size_h
    },
    getSize_w(){
        return self.size_w === null ? "" : self.size_w
    },
}))