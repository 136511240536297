import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import FormTableItem from "./FormTableItem";


const FormTable = () => {
  return (
    <Wrapper>
      <Container>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead1>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Пользователь</TableCell>
                <TableCell>Тема обращения</TableCell>
                <TableCell>Кол-во
                  сообщений</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell>Дата создания <br/>
                  Дата обновления</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead1>
            <TableBody>
              <FormTableItem />
            </TableBody>
          </Table>
        </TableContainer>

      </Container>
    </Wrapper>
  )
}

export default observer(FormTable);

const TableHead1 = styled(TableHead)``;

const MainButton = styled.button`
  background: transparent;
  border: none;
  border: 1px solid #353535;
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Arsenal", sans-serif;
  color: #353535;
  width: 100%;
  /* margin: 50px auto; */
`;
const Container = styled.div`
  width: calc(100% - 200px);
  margin: 0 auto;
`;
const SpinContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
