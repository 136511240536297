import React, { useEffect, useState } from "react";
import "../../Styles/CollectionersMini.css";
import CollectionersCard from "./CollectionersMiniCard";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { BaseURL } from "../../utils/baseURL";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";

const CollectionersMini = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [collect, setCollect] = useState([]);

  useEffect(() => {
    const getCollect = () => {
      setLoading(true);
      axios
        .get(`${BaseURL}/v1/get_arts/`)
        .then((res) => {
          //   console.log("🚀 ~ file: Artists.jsx:18 ~ .then ~ res:", res);
          if (res.data) {
            setCollect(res.data.results);
            // console.log(res.data)
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log("🚀 ~ file: Artists.jsx:22 ~ .then ~ err:", err);
        });
    };

    getCollect();
  }, []);

  return (
    <div className="wrapper-collectionersMini">
      <p className="narrowTopText__mini">Коллекционеры</p>
      <div className="container-collectionersMini">
        {!loading &&
          collect
            .filter((e) => e.status === "COLLECT")
            .slice(0, 6)
            .map((e, i) => {
              return (
                <CollectionersCard
                  key={i}
                  firstname={e.firstname}
                  lastname={e.lastname}
                  description={""}
                  pictures={e.user_groups}
                  avatar={e.avatar}
                />
              );
            })}
        {loading && (
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color="#353535"
            ariaLabel="three-dots-loading"
            wrapperClassName="loader-wrapper"
            wrapperStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          />
        )}
      </div>
      <button
        className="showMoreBtn"
        onClick={() => navigate("/collectioners")}
      >
        СМОТРЕТЬ БОЛЬШЕ
      </button>
    </div>
  );
};

export default observer(CollectionersMini);
