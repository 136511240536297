import React from "react";
import "../../Styles/Collectioners.css";
import styled from "styled-components";
import artistsMini from "../../image/Artist/artistsMini.png";
import workMini from "../../image/Artist/workMini.png";
import { usePersistentStore } from "../../store";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import CollectionersCardItem from "./CollectionersCardItem";

const CollectionersCard = ({
  firstname,
  lastname,
  description,
  pictures,
  avatar,
}) => {
  const { ArtistStore, UserStore } = usePersistentStore();
  const navigate = useNavigate();

  const handleGoToDetail = () => {
    ArtistStore.setFirstname(firstname);
    ArtistStore.setLastname(lastname);
    let picturesArray = pictures.map((e) => {
      return {
        name: e.title,
        url: e.group_pictures[0]?.image_url,
        pictures: e.group_pictures?.map((img) => {
          return img.image_url;
        }),
        id: e.id,
        description: e.description,
        price: e.group_pictures[0]?.price,
        status: e.group_pictures[0]?.status_pic,
        year: e.year,
        artist: e.artist,
        size_h: e.size_h,
        size_w: e.size_w,
      };
    });
    ArtistStore.setPictures(picturesArray);
    ArtistStore.setAvatar(avatar);
    ArtistStore.setDescription(description);
  };

  const handleClickMoreButton = () => {
    ArtistStore.setFirstname(firstname);
    ArtistStore.setLastname(lastname);
    let picturesArray = pictures.map((e) => {
      return {
        name: e.title,
        url: e.group_pictures[0]?.image_url,
        name: e.title,
        url: e.group_pictures[0]?.image_url,
        pictures: e.group_pictures?.map((img) => {
          return img.image_url;
        }),
        id: e.id,
        description: e.description,
        price: e.group_pictures[0]?.price,
        status: e.group_pictures[0]?.status_pic,
        year: e.year,
        artist: e.artist,
        size_h: e.size_h,
        size_w: e.size_w,
      };
    });
    ArtistStore.setPictures(picturesArray);
    ArtistStore.setAvatar(avatar);
    ArtistStore.setDescription(description);
    navigate("/worksArtist");
  };

  return (
    <Wrapper>
      <Container>
        <AboutCollect>
          <MainPicture>
            <Img src={avatar} alt="Picture" />
          </MainPicture>
          <MainInfo>
            <Title>
              {firstname} {lastname}
            </Title>
            <SubTitle>{description}</SubTitle>
          </MainInfo>
        </AboutCollect>
        <AboutWorks>
          <MainTitle>
            <WorkTitle>Коллекция</WorkTitle>
          </MainTitle>
          <MainWork>
            {pictures?.map((e, i) => {
              if (e.group_pictures[0]?.status_pic === "APPROVED") {
                return (
                  <CollectionersCardItem
                    key={i}
                    title={e.title}
                    picture={e.group_pictures[0]?.image_url}
                    id={e.id}
                    firstname={firstname}
                    lastname={lastname}
                    pictures={e.group_pictures}
                    description={e.description}
                    price={e.group_pictures[0]?.price}
                    year={e.year}
                    artist={e.artist}
                    size_h = {e.size_h}
                    size_w = {e.size_w}
                  />
                );
              } else {
                return <></>;
              }
            })}
          </MainWork>
          <WatchMore>
            <MainButton onClick={() => handleClickMoreButton()}>
              <StyledLink to="/worksCollect" onClick={() => handleGoToDetail()}>
                Смотреть больше
              </StyledLink>
            </MainButton>
          </WatchMore>
        </AboutWorks>
      </Container>
    </Wrapper>
  );
};

export default observer(CollectionersCard);

const Wrapper = styled.div`
  font-family: "Arsenal", sans-serif;
  color: #353535;
  display: flex;
  align-items: center;
  width: 100%;
`;
const Container = styled.div`
  width: 100%;
  margin: auto;
  max-width: 1170px;
  display: flex;
  gap: 30px;
  padding: 100px 0;
  border-top: 1px solid #b3b3b3;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;
const AboutCollect = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 370px;
  gap: 20px;
  @media (max-width: 480px) {
    justify-content: center;
    align-items: center;
  }
`;
const MainPicture = styled.div`
  width: 170px;
  height: 170px;
`;

const Img = styled.img`
  width: 170px;
  height: 170px;
  object-fit: cover;
`;

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 30.1px;
  @media (max-width: 480px) {
    text-align: center;
  }
`;
const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 480px) {
    text-align: center;
  }
`;

const AboutWorks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  flex-grow: 1;
`;
const MainTitle = styled.div`
  @media screen and (max-width: 580px) {
    text-align: center;
  }
`;
const WorkTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;

  @media screen and (max-width: 580px) {
    text-align: center;
  }
`;
const MainWork = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 30px;
  @media (max-width: 1000px) {
    gap: 20px;
  }
  @media (max-width: 580px) {
    gap: 10px;
  }
`;

const WatchMore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MainButton = styled.button`
  text-transform: uppercase;
  width: fit-content;
  background: transparent;
  background-color: #353535;
  border: none;
  padding: 23px 19px;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 4px darkgray;
  }

  &:active {
    transform: scale(0.99);
    box-shadow: 0 6px 4px darkgray;
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
`;
