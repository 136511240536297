import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import workMini from "../../image/Artist/workMini.png";
import { useNavigate } from "react-router-dom";
import { usePersistentStore } from "../../store";

const ArtistsCardItem = ({
  title,
  picture,
  id,
  firstname,
  lastname,
  pictures,
  description,
  price,
  year,
  artist,
  size_h,
  size_w,
}) => {
  const navigate = useNavigate();
  const { DetailedArtStore } = usePersistentStore();

  const handleClick = () => {
    navigate(`/store/card/id:${id}`);

    DetailedArtStore.setTitle(title);
    DetailedArtStore.setDescription(description);
    DetailedArtStore.setAuthor(firstname, lastname);

    let arrayOfPictures = pictures.map((e) => e.image_url);
    DetailedArtStore.setImages(arrayOfPictures);
    DetailedArtStore.setPrice(price);
    DetailedArtStore.setYear(year);
    DetailedArtStore.setArtist(artist);
    DetailedArtStore.setSize_h(size_h);
    DetailedArtStore.setSize_w(size_w);
  };

  return (
    <WorksBlock onClick={() => handleClick()}>
      <Img src={picture} alt="Picture" />
      {/* <PictureName>{firstname} {lastname}</PictureName> */}
      <PictureName>{title}</PictureName>
      {artist === null ?
        ""
        :
        <PictureName>{artist}</PictureName>
      }
      {year === null ?
        ""
        :
        <PictureName>{`${year} г.`}</PictureName>
      }
      {size_w && size_h !==0 ?
        <PictureName>{`Размер: ${size_w} x ${size_h}`}</PictureName>
        :
        ""
      }
      
      <PictureName>{price} ₽</PictureName>




    </WorksBlock>
  );
};

export default observer(ArtistsCardItem);

const Img = styled.img`
  width: 194px;
  height: 194px;
  object-fit: cover;
  transition: all 0.2s ease-in;

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.99);
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const WorksBlock = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: center;
  width: fit-content;

  cursor: pointer;
  @media (max-width: 1000px) {
    width: fit-content;
  }
  @media (max-width: 580px) {
    width: 100%;
  }
`;
const PictureName = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 20.06px;
  max-width: 194px;
  text-align: center;
  /* margin: 0; */
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 580px) {
    font-size: 16px;
    max-width: 100%;
  }
`;
