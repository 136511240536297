import React, { useState } from "react";
import styled from "styled-components";
import picture_3 from "../../image/picture_3.png";
import "swiper/swiper.min.css";
import Slider from "./Slider";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { BaseURL } from "../../utils/baseURL";
import { useNavigate } from "react-router-dom";
import { OtpInput } from "./OtpInput";
import UserStore from "../../store/UserStore";
import { usePersistentStore } from "../../store";

const Confirm = () => {
  const { UserStore } = usePersistentStore();
  const [otp, setOtp] = useState("");
  const onChange = (value) => setOtp(value);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  // const [code, setCode] = useState('')

  // const [otp, setOtp] = useState(new Array(6).fill(""))
  // const handleChange = (element, index) => {
  //   if (isNaN(element.value)) return false;

  //   setOtp([...otp.map( (d, idx)=> (idx === index) ? element.value : d )]);

  //   if (element.nextSibling) {
  //     element.nextSibling.focus();
  //   }
  // };

  const registOn = (e) => {
    e.preventDefault();

    const newLog = {};
    // console.log(newLog);

    const body = {
      code: otp,
      secret: UserStore.secret,
    };

    axios
      .post(`${BaseURL}/v1/confirm/`, body)
      .then((res) => {
        setError(!res.data.result);
        // console.log(res.data);
        if (res.data.result) {
          navigate("/Account/MyPictures");
        }
      })
      .catch((err) => {
        setError(true);
        console.log(err);
      });
  };

  return (
    <Wraper>
      <Container>
        <MainImage>
          <Slider />
        </MainImage>
        <Login>
          <MainText>
            <Title>Создание аккаунта</Title>
            <SubTitle>
              На вашу почту {UserStore.getConfirmEmail()} было отправлено письмо
              с кодом подтверждения
            </SubTitle>
          </MainText>
          <Board>
            {/* {
              otp.map ( (data, index) => {
                return (
                <Entry className='otp-field' type="text" maxLength={1} name="otp" key={index} value={data} onChange={e => handleChange(e.target, index)}
                onFocus={e => e.target.select ()}
                />  
                );
              } )
            }            */}

            <OtpInput value={otp} valueLength={6} onChange={onChange} />
          </Board>
          <LoginButton onClick={(e) => registOn(e)}>
            Зарегистрироваться
          </LoginButton>
        </Login>
      </Container>
    </Wraper>
  );
};

export default observer(Confirm);

const Wraper = styled.div`
  font-family: "Arsenal", sans-serif;
  color: #353535;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const MainImage = styled.div`
  width: 60%;
  max-height: 100vh;
  @media (max-width: 1000px) {
    display: flex;
    height: 100vh;
    width: 100%;
  }
  @media (max-width: 480px) {
    display: none;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 1000px) {
    justify-content: center;
    position: relative;
  }
`;
const Login = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 156px;
  @media (max-width: 1000px) {
    width: 50%;
    position: absolute;
    z-index: 100;
    background-color: #fff;
    padding: 50px 0;
  }
  @media (max-width: 768px) {
    width: 60%;
    gap: 100px;
  }
  @media (max-width: 480px) {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
  }
`;
const MainText = styled.div`
  text-align: center;
  width: calc(100% - 200px);
  @media (max-width: 1000px) {
    width: calc(100% - 70px);
  }
`;
const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 45.14px;
  margin: 0;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 30px;
    margin: 0;
  }
  @media (max-width: 320px) {
    font-size: 24px;
  }
`;
const SubTitle = styled.span`
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.06px;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 14px;
  }
  @media (max-width: 320px) {
    font-size: 12px;
    line-height: 14.4px;
  }
`;
const Board = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  @media (max-width: 1000px) {
    width: calc(100% - 70px);
  }
`;
const Entry = styled.input`
  border: none;
  border-bottom: 1px solid #b3b3b3;
  width: 40px;
  outline: none;
  transition: 0.3s;
  font-size: 52px;
  font-weight: 400;
  line-height: 62.41px;
  text-align: center;
  font-family: "Arsenal", sans-serif;
  :focus {
    border-bottom: 1px solid #353535;
  }
  @media (max-width: 1000px) {
    width: calc(100% - 150px);
  }
`;
const LoginButton = styled.button`
  background-color: #353535;
  border: none;
  color: #f6f6f6;
  padding: 25px 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.81px;
  width: calc(100% - 200px);
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1000px) {
    width: calc(100% - 70px);
  }
`;
