import React, { useState } from 'react'
import styled from "styled-components";
import MuiTable from './MuiTable';
import Search from "../../image/AdminImage/search.svg";
import { observer } from 'mobx-react-lite';

const AdminPage = () => {


  const [search, setSearch] = useState("");

  return (
    <Container>
      <SearchBlock>
        <EntryButton type="submit">
          <Img src={Search} alt="Picture" />
        </EntryButton>
        <Entry
          type="text"
          placeholder="Поиск..."
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </SearchBlock>

      <MuiTable filterValue={search} />
    </Container>
  )
}

export default observer(AdminPage);

const Img = styled.img``;

const Container = styled.div`

`;

const SearchBlock = styled.form`
  display: flex;
  margin: 30px auto;
  width: calc(100% - 200px);
`;
const Entry = styled.input`
  font-size: 15px;
  color: black;
  font-weight: 400;
  border: none;
  outline: none;

  &::placeholder {
    color: #b3b3b3;
  }
`;
const EntryButton = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
`;
