import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './Styles/App.css';
import App from './App';
import { PersistentStoreProvider } from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <PersistentStoreProvider>
        <BrowserRouter basename='/'>
            <App />
        </BrowserRouter>
    </PersistentStoreProvider>
);

