import React from 'react'
import styled from 'styled-components'
import MyWork from '../../image/Artist/MyWork.png'
import { observer } from 'mobx-react-lite'
import { useNavigate } from "react-router-dom";
import { usePersistentStore } from "../../store";
import { getSnapshot } from "mobx-state-tree";

const WorksArtistItem = ({
  title,
  url,
  id,
  firstname,
  lastname,
  pictures,
  description,
  price,
  year,
  artist,
  size_h,
  size_w,
}) => {

  const navigate = useNavigate();
  const { DetailedArtStore } = usePersistentStore();

  const handleClick = () => {
    navigate(`/store/card/id:${id}`);

    DetailedArtStore.setTitle(title);
    DetailedArtStore.setDescription(description);
    DetailedArtStore.setAuthor(firstname, lastname);
    DetailedArtStore.setImages(getSnapshot(pictures));
    DetailedArtStore.setPrice(price);
    DetailedArtStore.setYear(year);
    DetailedArtStore.setArtist(artist);
    DetailedArtStore.setSize_h(size_h);
    DetailedArtStore.setSize_w(size_w);
  };

  return (
    <InfoPictures onClick={() => handleClick()}>
      <ImgWork src={url} alt="Picture" />
      <PictureName>{firstname} {lastname}</PictureName>
      <PictureName>{title}</PictureName>
      {artist === null ?
        ""
        :
        <PictureName>{artist}</PictureName>
      }
      {year === null ?
        ""
        :
        <PictureName>{`${year} г.`}</PictureName>
      }
      {size_w && size_h !==0 ?
        <PictureName>{`Размер: ${size_w} x ${size_h}`}</PictureName>
        :
        ""
      }
      <PictureName>{price} ₽</PictureName>
    </InfoPictures>
  )
}

export default observer(WorksArtistItem);

const InfoPictures = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 23%;
  @media (max-width: 768px) {
    width: 30%;
  }
  @media (max-width: 480px) {
    width: 47%;
  }
`;
const PictureName = styled.span`
  font-size: 15px;
  font-weight: 700;
  line-height: 18.81px;
  text-align: center;
  
`;
const ImgWork = styled.img`
  width: 100%;
`;