import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import "../../Styles/Collectioners.css";
import Breadcrumbs from "../Breadcrumbs";
import CollectionersCard from "./CollectionersCard";
import { BaseURL } from "../../utils/baseURL";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";

const Collectioners = () => {
  const [collect, setCollect] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCollect = () => {
      setLoading(true);
      axios
        .get(`${BaseURL}/v1/get_arts/`)
        .then((res) => {
          //   console.log("🚀 ~ file: Artists.jsx:18 ~ .then ~ res:", res);
          if (res.data.results) {
            setCollect(res.data.results);
            // console.log(res.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("🚀 ~ file: Artists.jsx:22 ~ .then ~ err:", err);
        });
    };

    getCollect();
  }, []);

  return (
    <div className="wrapper-collectioners">
      <div className="container-collectioners">
        <Breadcrumbs />
        {!loading &&
          collect
            ?.filter((el) => el.status === "COLLECT")
            .map((e, i) => {
              return (
                <CollectionersCard
                  key={i}
                  firstname={e.firstname}
                  lastname={e.lastname}
                  description={e.description}
                  pictures={e.user_groups}
                  avatar={e.avatar}
                />
              );
            })}
        {loading && (
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#353535"
            ariaLabel="three-dots-loading"
            wrapperClassName="loader-wrapper"
            wrapperStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default observer(Collectioners);
