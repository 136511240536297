import React, { useState } from "react";
import { Link, Links } from "react-router-dom";
import "../../Styles/Header.css";
import logoMain from "../../image/logoMain.svg";
import logoM from "../../image/logoM.svg";
import iconSearch from "../../image/iconSearch.svg";
import iconHeart from "../../image/iconHeart.svg";
import iconShop from "../../image/iconShop.svg";
import iconContact from "../../image/iconContact.svg";
import Menu from "../../menu/menu";
import { observer } from "mobx-react-lite";
import { usePersistentStore } from "../../store";
import Favourites from "../FavouritesBlock/Favourites";
import Basket from "../BasketBlock/Basket";
import PurchaseBlock from "../Purhase/PurchaseBlock";

const Header = () => {
  const [menuActive, setMenuActive] = useState(false);
  const { UserStore } = usePersistentStore();
  const [openFavor, setOpenFavor] = useState(false);
  const [openBasket, setOpenBasket] = useState(false);
  const [openPurchase, setOpenPurchase] = useState(false);

  return (
    <div className="wrapper">
      <div className="container">
        <div className="header-block">
          {/* <Link to="/" className="n" onClick={() => setMenuActive(false)}>
                    <img src={logoMain} alt="Лого" id="logoMain"/>
                    </Link> */}
          <Link to="/" className="n" onClick={() => setMenuActive(false)}>
            <img src={logoM} alt="Лого" id="logoMain" />
          </Link>

          <div className="navigation-bar">
            {/* <Link to="/store" className="navigation-links">Магазин</Link> */}
            <Link to="/artists" className="navigation-links">
              Художники
            </Link>
            <Link to="/collectioners" className="navigation-links">
              Коллекционеры
            </Link>
            {/* <Link to="/themes" className="navigation-links">Темы</Link> */}
            {/* <Link to="/auctions" className="navigation-links">Аукционы</Link> */}
            {/* <Link to="/events" className="navigation-links events">Events</Link> */}
          </div>

          <div className="container-buttons">
            {/* <form className="form-search hide">
                            <input type='text' className="input-search" placeholder="Поиск"></input>

                            <button className="button iconSearch" ><img src={iconSearch} alt='' /></button>
                        </form> */}

            <button
              onClick={() => setOpenFavor(!openFavor)}
              className="button iconHeart hide"
            >
              <img src={iconHeart} alt="" />
            </button>
            {/* <button onClick={() => setOpenBasket(!openBasket)} className="button iconShop hide"><img src={iconShop} alt='' /></button> */}

            <Link
              to={
                UserStore.isAuthFunc()
                  ? "/Account/MyPictures"
                  : UserStore.getRole() === "admin"
                  ? "/admin/adminPage"
                  : "/MainLogin"
              }
            >
              <button className="button iconContact hide">
                <img src={iconContact} alt="" />
              </button>
            </Link>

            <div className="burger-nav">
              <div
                className="burger-btn"
                onClick={() => setMenuActive(!menuActive)}
              >
                <span />
              </div>
            </div>
          </div>
        </div>
        {openFavor && (
          <div className="modal-block">
            <Favourites openFavor={openFavor} setOpenFavor={setOpenFavor} />
          </div>
        )}
        {/* {openBasket && 
                    <div className="modal-block">
                        <Basket
                            openBasket={openBasket}
                            setOpenBasket={setOpenBasket}
                            setOpenPurchase={setOpenPurchase}
                            openPurchase = {openPurchase}
                        />
                    </div>
                    } */}
        {openPurchase && <PurchaseBlock setOpenPurchase={setOpenPurchase} />}
      </div>
      <Menu active={menuActive} setActive={setMenuActive} />
    </div>
  );
};

export default observer(Header);
