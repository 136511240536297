import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import axios from "axios";
import { BaseURL } from "../../utils/baseURL";
import { usePersistentStore } from "../../store";

const ModalDelete = ({ openModal, setOpenModal, id }) => {

  const { UserStore } = usePersistentStore();

  const deletePicture = (album_id) => {
      
    const body = {
      albums: [album_id],

    }
  
    axios
      .post(`${BaseURL}/v1/albums_delete/`, body, UserStore.getConfig() )
      .then((res) => {
        
      })
      .catch((err) => {
        console.log("🚀 ~ file: Store.jsx:28 ~ .then ~ err:", err);
      });
  };

  
  const handleDeleteButton = () => {
    deletePicture(id)
    setOpenModal(!openModal)
  }
  


  return (
    <Wrapper onClick={() => setOpenModal(!openModal)}>
      <Container onClick={e => e.stopPropagation()}>

        <Title>Удаление картины</Title>
        <SubTitle>Вы действительно хотите удалить картину?</SubTitle>
        <MainButton>
          <MainButtonDelete onClick={handleDeleteButton}>Удалить</MainButtonDelete>
          <MainButtonCancel onClick={() => setOpenModal(!openModal)}>Отмена</MainButtonCancel>
        </MainButton>


      </Container>
    </Wrapper>
  )
}

export default observer(ModalDelete)

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 475px;
  gap: 12px;
  background-color: #ffffff;
  padding: 35px 32px;
  
  @media screen and (max-width: 650px){
    margin-right: 20px;
    margin-left: 20px;
  }
  
  
`;


const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: #353535;
`;
const SubTitle = styled.span`
  font-size: 15px;
  
`;
const MainButton = styled.div`
  display: flex;
  gap: 50px;
`;

const MainButtonDelete = styled.button`
  background: transparent;
  border: none;
  color: #ffffff;
  background-color: #353535;
  font-size: 15px;
  font-weight: 700;
  padding: 15px 30px;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
    /* box-shadow: 0 6px 4px darkgray; */
  }

  &:active {
    transform: scale(0.99);
    /* box-shadow: 0 6px 4px darkgray; */
  }
`;
const MainButtonCancel = styled.button`
  background: transparent;
  border: none;
  border: 1px solid #353535;
  color: #353535;
  background-color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  padding: 15px 30px;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
    /* box-shadow: 0 6px 4px darkgray; */
  }

  &:active {
    transform: scale(0.99);
    /* box-shadow: 0 6px 4px darkgray; */
  }
`;