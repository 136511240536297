import React, { useState, useEffect } from "react";
import styled from "styled-components";
import picture_2 from "../../image/picture_2.png";
import { useNavigate } from "react-router-dom";
import Slider from "../Login/Slider";
import "swiper/swiper.min.css";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { BaseURL } from "../../utils/baseURL";
import UserStore from "../../store/UserStore";
import { usePersistentStore } from "../../store";
import { emailRegExp } from "../../utils/emailRegExp";
import { ThreeDots } from "react-loader-spinner";
import Modal from "../Modal/Modal";

const EmailConfirm = () => {
  const { UserStore } = usePersistentStore();

  const [email, setEmail] = useState("");
  
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [alreadyExist, setAlreadyExist] = useState(false);

  

  

  const registOn = (e) => {
    e.preventDefault();


    const newLog = {
      
      email,
      
    };
    // console.log(newLog)
    if (
      
      !!email &&
      emailRegExp.test(email)
    ) {
      setLoading(true);
      const body = {
        
        email,
        
      };

      axios
        .post(`${BaseURL}/v1/password_recovery/`, body)
        .then((res) => {
          if (res.data?.secret) {
            if (res.data.hasOwnProperty("secret")) {
              UserStore.setSecret(res.data.secret);
              UserStore.setEmail(email);
              navigate("/passConfirm");
            }
          } 
          // else if (res.data?.msg === "user already exist") {
          //   setAlreadyExist(true);
          // }
          // setLoading(false);

          //   console.log(res.data);
          // UserStore.setToken(res.data)
          // UserStore.getUserData();
        })
        .catch((err) => {
          console.log(err);
          setError(true);
          setLoading(false);
        });
    } else {
      setError(true);
      setLoading(false);
      // setPasswordValid(!!password_confirm.length)
    }
  };

  // useEffect(() => {
  //   if (passwordValid === false) {
  //     setPasswordValid(!!password_confirm.length)
  //   }
  // }, [password_confirm])

  return (
    <Wraper>
      <Container>
        <MainImage>
          {/* <Img src={picture_2} alt="Picture"/>   */}
          <Slider />
        </MainImage>
        <Login>
          <MainText>
            <Title>Восстановление аккаунта</Title>
            <SubTitle>Введите почту чтобы мы смогли отправить код восстановления</SubTitle>
          </MainText>

          <Board
            action="https://backend.kazakov.tk/api/v1/password_recovery/"
            method="post"
          >


            <Entry
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError(false);
              }}
              type="email"
              name="email"
              placeholder="Адрес электронной почты"
            />


            {error && <Errors>Неккоректные данные</Errors>}
            
          </Board>
          {!loading && (
            <LoginButton disabled={!email} onClick={registOn}>
              Отправить код на почту
            </LoginButton>
          )}
          {loading && (
            <ThreeDots
              height="40"
              width="40"
              radius="9"
              color="#353535"
              ariaLabel="three-dots-loading"
              wrapperClassName="loader-wrapper"
              wrapperStyle={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            />
          )}
        </Login>
      </Container>
      {/* {alreadyExist && (
        <Modal
          closeFunction={() => setAlreadyExist(false)}
          title="Ошибка регистарции"
          subtitle="Пользователь уже существует!"
          text="Воспользуйтесь другой почтой или обратитесь в поддержку"
          buttonText="Попробовать снова"
          handleOk={() => setAlreadyExist(false)}
        />
      )} */}
    </Wraper>
  );
};

export default observer(EmailConfirm);

const MainText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Wraper = styled.div`
  font-family: "Arsenal", sans-serif;
  color: #353535;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const MainImage = styled.div`
  width: 60%;
  max-height: 100vh;

  @media (max-width: 1000px) {
    display: flex;
    height: 100vh;
    width: 100%;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

const Img = styled.img`
  width: 100%;
  object-fit: cover;
  max-height: 100vh;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  

  @media (max-width: 1000px) {
    justify-content: center;
    position: relative;
    min-height: 100vh;
  }
`;

const Login = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 80px;

  @media (max-width: 1000px) {
    width: 60%;
    position: absolute;
    z-index: 100;
    background-color: #fff;
    padding: 50px 0;
  }

  @media (max-width: 768px) {
    width: 60%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 45.14px;
  margin: 0;
  /* margin-bottom: 20px; */

  @media (max-width: 768px) {
    width: calc(100% - 70px);
    text-align: center;
  }

  @media (max-width: 320px) {
    width: calc(100% - 56px);
    font-size: 24px;
  }
`;

const SubTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20.06px;
  /* margin-bottom: 80px; */

  @media (max-width: 768px) {
    width: calc(100% - 70px);
    text-align: center;
    font-size: 14px;
  }

  @media (max-width: 320px) {
    width: calc(100% - 52px);
    font-size: 12px;
    line-height: 14.4px;
  }
`;

const Board = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 50px;
`;

const Entry = styled.input`
  font-family: "Arsenal", sans-serif;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #b3b3b3;
  width: calc(100% - 200px);
  /* margin-bottom: 50px; */
  gap: 50px;
  outline: none;
  transition: 0.3s;
  font-size: 16px;

  :focus {
    border-bottom: 1px solid #353535;
  }

  @media (max-width: 1000px) {
    width: calc(100% - 70px);
  }
`;

const EntryValid = styled.input`
  font-family: "Arsenal", sans-serif;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid  ${(props) => props.$highlight ? "red" : "#D7D7D7"};
  width: calc(100% - 200px);
  /* margin-bottom: 50px; */
  gap: 50px;
  outline: none;
  transition: 0.3s;
  font-size: 16px;
    

  :focus {
    border-bottom: 1px solid #353535;
  }

  @media (max-width: 1000px) {
    width: calc(100% - 70px);
  }
`;

const LoginButton = styled.button`
  background-color: #353535;
  color: #f6f6f6;
  border: none;
  padding: 25px 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.81px;
  width: calc(100% - 200px);
  transition: all 0.2s ease-in;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 4px darkgray;
  }

  &:active {
    transform: scale(0.99);
    box-shadow: 0 6px 4px darkgray;
  }

  @media (max-width: 1000px) {
    width: calc(100% - 70px);
  }
`;

const Errors = styled.div`
  color: red;
`;
