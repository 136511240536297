import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MyPicture from "../../image/Account/MyPicture.png";
import miniBasket from "../../image/Account/miniBasket.svg";
import ModalDelete from "./ModalDelete";
import EditIconSVG from "../../image/Account/edit-major-svgrepo-com.svg";
import { usePersistentStore } from "../../store";
import { useNavigate } from "react-router-dom";
import ArrowLeftImg from "../../image/Account/BackVector.svg";
const TechSupportChat = () => {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const backToTable = () => {
    navigate("/Account/techSupport");
  };

  const demoData = [
    {
      name: "Александр",
      date: "28.09.2023",
      messages:
        "Структура уязвима. С семантической точки зрения, мужская рифма отталкивает былинный холодный цинизм. Скрытый смысл точно аллитерирует словесный диалогический контекст.",
    },
    {
      name: "Александр",
      date: "28.09.2023",
      messages: "хуй",
    },
  ];
  const [demoDataState, setDemoDataState] = useState(demoData);
  function pushMessage() {
    setDemoDataState((demoData) => {
      if (message !== "") {
        demoData.push({
          name: "Александр",
          date: "28.09.2023",
          messages: message,
        });
        return demoData;
      }
      return demoData;
    });
  }

  function senMessage() {
    pushMessage();
    setMessage("");
  }
  useEffect(() => {
    pushMessage();
    senMessage();
  }, []);
  console.log(demoDataState);
  return (
    <>
      <ChatContainer>
        <BackBlock>
          <img src={ArrowLeftImg} />
          <Title onClick={backToTable}>Вернуться к списку</Title>
        </BackBlock>
        <StatusBar>
          <LeftSideContent>
            <StatusInfoBlock>
              <InfoTitle>Тема обращения:</InfoTitle>
              <InfoSubTitle>Пингвины спиздили картину</InfoSubTitle>
            </StatusInfoBlock>
            <StatusInfoBlock>
              <InfoTitle>Дата обновления:</InfoTitle>
              <InfoSubTitle>11:48 20.09.2023 </InfoSubTitle>
            </StatusInfoBlock>
          </LeftSideContent>
          <StatusChat>В ожидании ответа</StatusChat>
        </StatusBar>
        <ChatBlock>
          <TopUserInfo>
            <UserRole>Администратор</UserRole>
            <UserEmail>oldi-dev@gmail.com</UserEmail>
          </TopUserInfo>

          <MessagesBlock>
            {demoDataState.map((e) => {
              return (
                <MessageItem>
                  <MessageInfo>
                    <NameSender>{e.name}</NameSender>
                    <DateMessage>{e.date}</DateMessage>
                  </MessageInfo>
                  <TextMessage>{e.messages}</TextMessage>
                </MessageItem>
              );
            })}
          </MessagesBlock>

          <BottomBar>
            <InputMessage
              type="text"
              placeholder="Написать сообщение"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <SendMessageBtn onClick={senMessage}>
              Отправить сообщение
            </SendMessageBtn>
          </BottomBar>
        </ChatBlock>
      </ChatContainer>
    </>
  );
};

export default observer(TechSupportChat);

const MessagesBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  overflow-y: scroll;
  padding: 25px 0px;
  width: 100%;
`;

const MessageItem = styled.div`
  background-color: #fff;
  padding: 16px 24px;
  width: fit-content;
  max-width: 525px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* align-self: flex-end; */
`;

const NameSender = styled.div`
  color: #353535;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const DateMessage = styled.div`
  color: #949494;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const MessageInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const TextMessage = styled.div`
  overflow: hidden;
  color: #353535;
  text-overflow: ellipsis;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ChatContainer = styled.div`
  padding: 34px 16px 100px;
  margin: 0 auto;
  min-width: 1170px;
  max-width: 1170px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const BackBlock = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;

const Title = styled.div`
  color: #353535;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StatusBar = styled.div`
  padding: 16px 20px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftSideContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StatusInfoBlock = styled.div`
  display: flex;
  gap: 8px;
`;

const InfoTitle = styled.div`
  color: #949494;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const InfoSubTitle = styled.div`
  color: #353535;
  text-align: right;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const StatusChat = styled.div`
  color: #fff;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 14.5px 24.5px;
  background-color: #949494;
`;

const ChatBlock = styled.div`
  background-color: #f6f6f6;
  padding: 16px 20px 32px;
  min-height: 630px;
  max-height: 630px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TopUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 0 16px 0;
  border-bottom: 1px solid #d9d9d9;
`;
const UserRole = styled.div`
  color: #353535;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const UserEmail = styled.div`
  color: #949494;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const BottomBar = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

const InputMessage = styled.input`
  width: 75%;
  padding: 16px;
  color: #949494;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

`;
const SendMessageBtn = styled.button`
  width: 23%;
  padding: 16px 42px;
  cursor: pointer;
  background-color: #353535;
  color: #fff;
  font-family: Arsenal;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
