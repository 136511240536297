import React from 'react'
import styled from 'styled-components'
import theme_1 from '../../image/theme/theme_1.png'
import { observer } from 'mobx-react-lite'


const ThemesItem = ({title, image}) => {
  return (
    <CardTheme>
      <Img src={image} alt="Picture" />
      <ThemeTitle>{title}</ThemeTitle>
    </CardTheme>
  )
}

export default observer(ThemesItem);

const CardTheme = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 22%;
    @media (max-width: 800px) {
        width: 30%;
    }
    
    @media (max-width: 480px) {
        width: 48%;
    }
    
    
`;
const Img = styled.img`
    width: 100%;
`;
const ThemeTitle = styled.button`
    position: absolute;
    font-size: 18px;
    z-index: 10;
    bottom: 30px;
    width: calc( 100% - 80px );
    margin: auto;
    background-color: #fff;
    color: #353535;
    border: none;
    padding: 17px 0;
    @media (max-width: 800px) {
      width: calc( 100% - 40px );
    }
    @media (max-width: 480px) {
    width: calc(100% - 10px );
     }
`;