import React, { useState } from "react";
import "./Menu.css";
import { Link, Links } from "react-router-dom";
import iconHeart from '../image/iconHeart.svg'
import iconShop from '../image/iconShop.svg'
import iconContact from '../image/iconContact.svg'
import iconSearch from '../image/iconSearch.svg'
import { observer } from "mobx-react-lite";
import Favourites from "../components/FavouritesBlock/Favourites";
import Basket from "../components/BasketBlock/Basket";

const Menu = ({ active, setActive }) => {

  const [openFavor,setOpenFavor] = useState (false);
  const [openBasket, setOpenBasket] = useState (false);


  return (
    <div className={active ? "menu active" : "menu"}>
      <div className="menu__content">
        {/* <Link
          to="/store"
          className="links-menu"
          onClick={() => setActive(false)}
        >
          Магазин
        </Link> */}

        <Link
          to="/artists"
          className="links-menu"
          onClick={() => setActive(false)}
        >
          Художники
        </Link>

        {/* <Link
          to="/themes"
          className="links-menu"
          onClick={() => setActive(false)}
        >
          Темы
        </Link> */}
        
         <Link
          to="/collectioners"
          className="links-menu"
          onClick={() => setActive(false)}
        >
          Коллекционеры
        </Link>

        {/* <Link
          to="/auctions"
          className="links-menu"
          onClick={() => setActive(false)}
        >
          Аукционы
        </Link> */}
        {/* <Link
          to="/events"
          className="links-menu events"
          onClick={() => setActive(false)}
        >
          Events
        </Link> */}
        <div className="menu__wrap">
          {/* <form>
            <input type='text' className="input-search" placeholder="Поиск"></input>
            <button className="menu__button iconSearch"><img src={iconSearch} alt=''/></button>
          </form> */}

          <button onClick={() => setOpenFavor(!openFavor)} className="menu__button iconHeart "><img src={iconHeart} alt=''/></button>
          {/* <button onClick={() => setOpenBasket(!openBasket)} className="menu__button iconShop "><img src={iconShop} alt=''/></button> */}
          <Link 
            to="/MainLogin"
            onClick={() => setActive(false)}
          >
            <button className="menu__button iconContact ">
              <img src={iconContact} alt=''/>
            </button>         
          </Link>
                                {openFavor && 
                                  <div className="modal-block">
                                      <Favourites
                                        openFavor={openFavor}
                                        setOpenFavor={setOpenFavor}
                                      />
                                  </div>
                                  }
                                  {/* {openBasket && 
                                  <div className="modal-block">
                                      <Basket
                                        openBasket={openBasket}
                                        setOpenBasket={setOpenBasket}
                                      />
                                  </div>
                                } */}
        </div>
      </div>
      
    </div>
  );
};

export default observer(Menu);
