import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import BackVector from "../../image/Account/BackVector.png";
import axios from "axios";
import { BaseURL } from "../../utils/baseURL";
import { usePersistentStore } from "../../store";
import changePhotoOne from "../../image/Account/changePhotoOne.png";
import { ThreeDots } from "react-loader-spinner";
import Modal from "../Modal/Modal";

const EditArt = () => {
  const { UserStore, DetailedArtStore } = usePersistentStore();
  const [selectedPicture, setSelectedPicture] = useState(
    DetailedArtStore.getImages()[0]
  );
  const [picturesIds, setPicturesIds] = useState(
    DetailedArtStore.getPicturesIds()
  );
  const [files, setFiles] = useState(DetailedArtStore.getImages());
  const [description, setDescription] = useState(
    DetailedArtStore.getDescription()
  );
  const [price, setPrice] = useState(DetailedArtStore.getPrice());
  const [theme, setTheme] = useState("");
  const [name, setName] = useState(DetailedArtStore.getTitle());

  const [year, setYear] = useState(DetailedArtStore.getYear());
  const [artist, setArtist] = useState(DetailedArtStore.getArtist());
  const [size_h, setSize_h] = useState(DetailedArtStore.getSize_h());
  const [size_w, setSize_w] = useState(DetailedArtStore.getSize_w());

  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const { id } = useParams();

  const handleAddFile = (e) => {
    if (e.target.files) {
      let formData = new FormData();
      formData.append("picture", e.target.files[0]);

      axios
        .post(`${BaseURL}/upload-image`, formData, UserStore.getConfig())
        .then((res) => {
          setFiles((oldArray) => [...oldArray, res.data.data.url]);
          setPicturesIds((oldArray) => [...oldArray, res.data.data.id]);
          setSelectedPicture(res.data.data.url);
        })
        .cacth((err) => {
          console.log("🚀 ~ file: AddPicture.jsx:33 ~ ).cacth ~ err:", err);
        });
    }
  };

  const deletePhoto = (e, index) => {
    e.preventDefault();
    setSelectedPicture("");
    let newArrayOfFiles = files.filter((e, i) => i !== index);
    setFiles(newArrayOfFiles);

    let newArrayOfIds = picturesIds.filter((e, i) => i !== index);
    setPicturesIds(newArrayOfIds);
  };

  const handleSendPicture = () => {
    setSuccess(false);
    setErr(false);
    setLoading(true);

    const body = {
      pictures_pk: picturesIds,
      title: name,
      description: description,
      price: Number(price),
      pk: parseInt(id),
      image_url: files,
      year: year,
      artist: artist,
      size_h: size_h,
      size_w: size_w,
    };

    axios
      .post(`${BaseURL}/v1/change_post_pictures/`, body, UserStore.getConfig())
      .then((res) => {
        // console.log("🚀 ~ file: AddPicture.jsx:50 ~ .then ~ res:", res);
        if (res.data.result) {
          setLoading(false);
          setSuccess(true);
        }
      })
      .catch((err) => {
        console.log("🚀 ~ file: AddPicture.jsx:53 ~ .then ~ err:", err);
        setLoading(false);
        setErr(true);
      });
  };

  return (
    <Container>
      <Wrap>
        <BackAccount>
          <img src={BackVector} alt="Picture" />
          <StyledLink to="/Account/MyPictures">
            Вернуться в “Личный кабинет”
          </StyledLink>
        </BackAccount>
        <PictureBlock>
          <PicturePhoto>
            <MainBlockImg
              background={!!selectedPicture ? "none" : "#eaeaea"}
              htmlFor={!!!selectedPicture ? "input_file" : ""}
            >
              {/* <AddEntry
                type="file"
                id="input_file"
                onInput={(e) => handleAddFile(e)}
                accept=".img, .jpg, jpeg, .png, .gif, .svg"
              /> */}
              <ImgMain
                htmlFor={!!!selectedPicture ? "input_file" : ""}
                src={!!selectedPicture ? selectedPicture : changePhotoOne}
                alt="Picture"
              />
              {!!selectedPicture || (
                <ImgTitle htmlFor={!!!selectedPicture ? "input_file" : ""}>
                  Добавить фото картины
                </ImgTitle>
              )}
            </MainBlockImg>

            <AddPhoto>
              {!!files[0] ? (
                <ContainerPhoto>
                  <DownloadedImg
                    src={files[0]}
                    alt="picture"
                    onClick={() => setSelectedPicture(files[0])}
                  />
                  <ButtonDelete onClick={(e) => deletePhoto(e, 0)}>
                    Удалить
                  </ButtonDelete>
                </ContainerPhoto>
              ) : (
                <ContainerPhoto>
                  <ChangePhoto>
                    <AddEntry
                      type="file"
                      id="input_file"
                      onInput={(e) => handleAddFile(e)}
                      accept=".img, .jpg, jpeg, .png, .gif, .svg"
                    />
                    <StyledLabel htmlFor="input_file">
                      <Img src={changePhotoOne} alt="picture" />
                    </StyledLabel>
                  </ChangePhoto>
                  {/* <ButtonDelete onClick={() => deletePhoto(1)}>Удалить</ButtonDelete> */}
                </ContainerPhoto>
              )}
              {!!files[1] ? (
                <ContainerPhoto>
                  <DownloadedImg
                    src={files[1]}
                    alt="picture"
                    onClick={() => setSelectedPicture(files[1])}
                  />
                  <ButtonDelete onClick={(e) => deletePhoto(e, 1)}>
                    Удалить
                  </ButtonDelete>
                </ContainerPhoto>
              ) : (
                <ContainerPhoto>
                  <ChangePhoto>
                    <AddEntry
                      type="file"
                      id="input_file"
                      onInput={(e) => handleAddFile(e)}
                      accept=".img, .jpg, jpeg, .png, .gif, .svg"
                    />
                    <StyledLabel htmlFor="input_file">
                      <Img src={changePhotoOne} alt="picture" />
                    </StyledLabel>
                  </ChangePhoto>
                  {/* <ButtonDelete disabled>Удалить</ButtonDelete> */}
                </ContainerPhoto>
              )}

              {!!files[2] ? (
                <ContainerPhoto>
                  <DownloadedImg
                    src={files[2]}
                    alt="picture"
                    onClick={() => setSelectedPicture(files[2])}
                  />
                  <ButtonDelete onClick={(e) => deletePhoto(e, 2)}>
                    Удалить
                  </ButtonDelete>
                </ContainerPhoto>
              ) : (
                <ContainerPhoto>
                  <ChangePhoto>
                    <AddEntry
                      type="file"
                      id="input_file"
                      onInput={(e) => handleAddFile(e)}
                      accept=".img, .jpg, jpeg, .png, .gif, .svg"
                    />
                    <StyledLabel htmlFor="input_file">
                      <Img src={changePhotoOne} alt="picture" />
                    </StyledLabel>
                  </ChangePhoto>
                  {/* <ButtonDelete disabled>Удалить</ButtonDelete> */}
                </ContainerPhoto>
              )}

              {!!files[3] ? (
                <ContainerPhoto>
                  <DownloadedImg
                    src={files[3]}
                    alt="picture"
                    onClick={() => setSelectedPicture(files[3])}
                  />
                  <ButtonDelete onClick={(e) => deletePhoto(e, 3)}>
                    Удалить
                  </ButtonDelete>
                </ContainerPhoto>
              ) : (
                <ContainerPhoto>
                  <ChangePhoto>
                    <AddEntry
                      type="file"
                      id="input_file"
                      onInput={(e) => handleAddFile(e)}
                      accept=".img, .jpg, jpeg, .png, .gif, .svg"
                    />
                    <StyledLabel htmlFor="input_file">
                      <Img src={changePhotoOne} alt="picture" />
                    </StyledLabel>
                  </ChangePhoto>
                  {/* <ButtonDelete disabled>Удалить</ButtonDelete> */}
                </ContainerPhoto>
              )}
            </AddPhoto>
          </PicturePhoto>
          <PictureInfo>
            <Entry
              type="text"
              name="name"
              placeholder="Название картины"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <Description>
              <Info>Описание картины</Info>
              <EntryInfo
                type="text"
                name="info"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </Description>
            <Entry
              type="text"
              name="year"
              placeholder="Год создания"
              onChange={(e) => setYear(e.target.value)}
              value={year}
            />
            <Entry
              type="text"
              name="artist"
              placeholder="Автор картины"
              onChange={(e) => setArtist(e.target.value)}
              value={artist}
            />
            <BoxSizePicture>
              <Entry
                type="text"
                name="size_h"
                placeholder="Высота картины"
                onChange={(e) => setSize_h(e.target.value)}
                value={size_h}
              />
              <Entry
                type="text"
                name="size_w"
                placeholder="Ширина картины"
                onChange={(e) => setSize_w(e.target.value)}
                value={size_w}
              />
            </BoxSizePicture>
            {/* <Entry type="text" name="size" placeholder="Размер картины" /> */}
            {/* <Entry
              type="text"
              name="theme"
              placeholder="Тема картины"
              onChange={(e) => setTheme(e.target.value)}
            /> */}
            {/* <List name="theme" onChange={(e) => setTheme(e.target.value)}>
              <option value="NameTheme">Тема картины</option>
              <option value="Trees">Деревья</option>
              <option value="Summer">Лето</option>
              <option value="Portraits">Портреты</option>
              <option value="Animals">Животные</option>
              <option value="Plants">Растения</option>
              <option value="Sea">Море</option>
              <option value="Homes">Дома</option>
              <option value="Russia">Русь</option>
            </List> */}
            <Check>
              <EntryCost
                type="number"
                name="cost"
                placeholder="Введите стоимость"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              />
              {!loading && (
                <MainButton
                  disabled={
                    !picturesIds 
                  }
                  onClick={() => handleSendPicture()}
                >
                  Отправить на проверку
                </MainButton>
              )}
              {loading && (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#353535"
                  ariaLabel="three-dots-loading"
                  wrapperClassName="loader-wrapper"
                  wrapperStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                />
              )}
            </Check>
            {err && (
              <Modal
                closeFunction={() => setErr(false)}
                title="Ошибка"
                subtitle="Произошла ошибка при добавлении картины!"
                text="Повторите попытку позже или обратитесь в поддержку."
                buttonText="Закрыть"
                handleOk={() => setErr(false)}
              />
            )}
            {success && (
              <Modal
                closeFunction={() => setSuccess(false)}
                title="Успешно"
                subtitle="Ваша картина добавлена успешно!"
                text="После одобрения администратором, она будет доступна к просмотру всем посетителям сайта"
                buttonText="Продолжить"
                handleOk={() => {
                  setSuccess(false);
                  navigate("/Account/MyPictures");
                }}
              />
            )}
          </PictureInfo>
        </PictureBlock>
      </Wrap>
    </Container>
  );
};

export default observer(EditArt);

const BoxSizePicture = styled.div`
  display: flex;
  justify-content: space-between;
`;


const Container = styled.div`
  font-family: "Arsenal", sans-serif;
  color: #353535;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  width: 100%;
  margin-top: 50px;
`;

const Wrap = styled.div`
  font-family: "Arsenal", sans-serif;
  color: #353535;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: calc(100% - 256px); */
  max-width: 1170px;
  gap: 30px;
  margin-bottom: 40px;
  @media (max-width: 1100px) {
    width: calc(100% - 40px);
  }
`;

const BackAccount = styled.div`
  display: flex;
  gap: 20px;
  justify-content: start;
  width: 100%;
`;
const Img = styled.img`
  width: 100px;
  height: 100px;
  object-fit: fill;
`;

const DownloadedImg = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  :hover,
  :focus {
    border: 2px solid #353535;
  }
`;
const MainBlockImg = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 446px;
  height: 400px;
  gap: 22px;
  overflow: hidden;

  /* object-fit: cover; */
  background: ${(props) => props.background};
  @media (max-width: 1000px) {
    width: calc(100% - 40px);
    width: 470px;
    height: 440px;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 300px;
  }
`;

const ImgTitle = styled.label`
  font-size: 15px;
  font-weight: 700;
  line-height: 18.81px;
  color: #353535;
  display: block;
  margin-top: -50px;
`;

const ImgMain = styled.img`
  min-width: 0;
  max-width: 100%;
  object-fit: cover;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #353535;
`;

const PictureBlock = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PicturePhoto = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1000px) {
    justify-content: center;
    align-items: center;
  }
`;

const PictureInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  @media (max-width: 1000px) {
    width: 50%;
  }
  @media (max-width: 786px) {
    width: 70%;
  }
  @media (max-width: 400px) {
    width: 90%;
  }
`;

const Entry = styled.input`
  font-family: "Arsenal", sans-serif;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 10px;
  outline: none;
  transition: 0.3s;

  :focus {
    border-bottom: 1px solid #353535;
  }
`;

const EntryInfo = styled.textarea`
  font-family: "Arsenal", sans-serif;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border: 1px solid #b3b3b3;
  outline: none;
  transition: 0.3s;
  resize: none;
  min-height: 250px;
  :focus {
    border: 1px solid #353535;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Info = styled.label`
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #b3b3b3;
`;

const Check = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const EntryCost = styled.input`
  width: 40%;
  font-family: "Arsenal", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  border: none;
  border: 1px solid #b3b3b3;
  outline: none;
  transition: 0.3s;
  padding: 25px 20px;
  :focus {
    border: 1px solid #353535;
  }
  @media (max-width: 1000px) {
    max-width: 30%;
  }
`;

const MainButton = styled.button`
  width: fit-content;
  background-color: #353535;
  color: #f6f6f6;
  padding: 18px 10px;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  border: none;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1000px) {
    width: 70%;
    font-size: 12px;
    padding: 0;
    padding: 17px;
  }
  @media (max-width: 768px) {
    padding: 0;
    padding: 26px;
  }
  @media (max-width: 520px) {
    font-size: 10px;
    padding: 0;
    padding: 25px 0px;
  }
`;

const AddPhoto = styled.form`
  display: flex;
  justify-content: space-around;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const ChangePhoto = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  /* height: 140px; */
  background-color: #eaeaea;
  :hover,
  :focus {
    border: 2px solid #353535;
  }
`;

const AddEntry = styled.input`
  display: none;
`;

const StyledLabel = styled.label``;

const List = styled.select`
  font-family: "Arsenal", sans-serif;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 10px;
  outline: none;
  transition: 0.3s;
  :focus {
    border-bottom: 1px solid #353535;
  }
`;

const ContainerPhoto = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  gap: 10px;

  @media (max-width: 1000px) {
    width: fit-content;
  }
  @media (max-width: 768px) {
    width: fit-content;
  }
  @media (max-width: 560px) {
    width: fit-content;
    align-items: center;
  }
`;
const ButtonDelete = styled.button`
  cursor: pointer;
  color: #353535;
  background: transparent;
  border: none;
  :hover,
  :focus {
    color: #b3b3b3;
  }
`;

const Errors = styled.div`
  color: red;
`;

const Title = styled.span`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  color: #353535;
`;
