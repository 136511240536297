import './Styles/App.css';
import { Route, Routes, Links, BrowserRouter, Navigate } from 'react-router-dom';
import Header from './components/Main page/Header';
import Footer from './components/Main page/Footer';
import MainLogin from './components/Login/MainLogin'
import Register from './components/Login/Register'
import Confirm from './components/Login/Confirm'
import Account from './components/Account/Account'
import EmailConfirm from './components/RecoveryPass/EmailConfirm'
import MyPictures from './components/Account/MyPictures'
import EditProfile from './components/Account/EditProfile'
import InfoAdd from './components/Account/InfoAdd'
import Artists from './components/Artists/Artists';
import Themes from './components/ThemeBlock/Themes';
import Collectioners from './components/Collectioners/Collectioners';
import Store from './components/Store/Store';
import Auctions from './components/Auctions/Auctions';
import Events from './components/Events page/Events';
import { useState } from 'react';
import Main from './components/Main page/Main';
import ScrollToTop from './ScrollToTop';
import StoreCardPage from './components/Store/StoreCardPage';
import UserStore from './store/UserStore';
import { usePersistentStore } from './store';
import { observer } from 'mobx-react-lite';
import AddPicture from './components/Account/AddPicture';
import Admin from './components/AdminUser/Admin';
import WorksArtist from './components/Artists/WorksArtist';
import WorksCollect from './components/Collectioners/WorksCollect';
import PassConfirm from './components/RecoveryPass/PassConfirm';
import EditArt from './components/EditArt/EditArt';
import ConsiderPicture from './components/AdminUser/ConsiderPicture';
import AdminPage from './components/AdminUser/AdminPage';
import AdminForm from './components/AdminForm/AdminForm';
import TechSupport from './components/Account/TechSupportTable';
import TechSupportChat from './components/Account/TechSupportChat';

function App() {
  const { UserStore } = usePersistentStore();

  return (
    <div className="App">
      <ScrollToTop>

        <Header />
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/store' element={<Store />} />
          <Route path='/store/card/id:id' element={<StoreCardPage />} />
          <Route path='/store/card/considerPicture/id:id' element={<ConsiderPicture />} />
          <Route path='/artists' element={<Artists />} />
          <Route path='/worksArtist' element={<WorksArtist />} />
          <Route path='/themes' element={<Themes />} />
          <Route path='/events' element={<Events />} />
          <Route path='/collectioners' element={<Collectioners />} />
          <Route path='/worksCollect' element={<WorksCollect />} />
          {
            UserStore.getRole() === 'admin' &&
            
            <Route path='/admin' element={<Admin />}>
              <Route path='adminPage' element={<AdminPage/>}/>
              <Route path='adminForm' element={<AdminForm/>}/>
            </Route>
          }
          {UserStore.isAuthFunc() ? (
            <>
              <Route path='/MainLogin' element={<Navigate to="/Account/MyPictures" replace={false} />} />
              <Route path='/Account' element={<Account />}>
                <Route path='MyPictures' element={<MyPictures />} />
                <Route path='EditProfile' element={<EditProfile />} />
                <Route path='InfoAdd' element={<InfoAdd />} />
                <Route path='techSupport' element={<TechSupport />} />
              </Route>
              <Route path='/AddPicture' element={<AddPicture />} />
              <Route path='/editArt/:id' element={<EditArt />} />

            </>
          ) : (
            <>
              <Route path='/Account/*' element={<Navigate to="/MainLogin" replace={false} />} />
              <Route path='/MainLogin' element={<MainLogin />} />

            </>
          )}
          <Route path='/MainLogin' element={<MainLogin />} />
          <Route path='/Register' element={<Register />} />
          <Route path='/Confirm' element={<Confirm />} />
          <Route path='/emailConfirm' element={<EmailConfirm />} />
          <Route path='/passConfirm' element={<PassConfirm />} />
          <Route path='/techSupportChat' element={<TechSupportChat />} />

        </Routes>



        <Footer />
      </ScrollToTop>
    </div>
  );
}

export default observer(App);
