import React from "react";
import "../../Styles/Modal.css";

const Modal = ({
  closeFunction,
  title,
  subtitle,
  text,
  handleOk,
  buttonText,
}) => {
  return (
    <div className="modalWrapper" onClick={() => closeFunction()}>
      <div className="modalContainer" onClick={(e) => e.stopPropagation()}>
        <div className="modalTitle">{title}</div>
        <div className="modalContentContainer">
          <div className="modalSubtitle">{subtitle}</div>
          <div className="modalText">{text}</div>
        </div>
        <div className="modalSubmitButton" onClick={() => handleOk()}>
          {buttonText}
        </div>
      </div>
    </div>
  );
};

export default Modal;
