import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MyPicture from "../../image/Account/MyPicture.png";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MyPictureItem from "./MyPictureItem";
import axios from "axios";
import { BaseURL } from "../../utils/baseURL";
import { usePersistentStore } from "../../store";
import { getStatus } from "../../utils/getStatus";
import { ThreeDots } from "react-loader-spinner";

const MyPictures = () => {
  const { UserStore } = usePersistentStore();

  const navigate = useNavigate();
  const registOn = () => {
    navigate("/AddPicture");
  };

  const [pictures, setPictures] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPictures = () => {
      setLoading(true);
      axios
        .get(`${BaseURL}/v1/get_arts/`, UserStore.getConfig())
        .then((res) => {
          setPictures(res.data.results);
          setLoading(false);
        })
        .catch((err) => {
          console.log("🚀 ~ file: Store.jsx:28 ~ .then ~ err:", err);
        });
    };

    getPictures();
  }, []);


  return (
    <Wraper>
      <AddPic>
        <MainButton onClick={() => registOn()}>Добавить картину</MainButton>
        <MainText>До 4-х фоток</MainText>
      </AddPic>
      <Pictures>
        {!loading &&
          pictures
            ?.filter((e) => e.uuid === UserStore.getUUID())[0]
            ?.user_groups.map((e, i) => {
              let arrayOfPictures = e.group_pictures?.map((item) => {
                return item.image_url;
              });
              let pictiresIds = e.group_pictures?.map((item) => {
                return item.id;
              });

              return (
                <MyPictureItem
                  key={i}
                  picture={e.group_pictures[0]?.image_url}
                  title={e.title}
                  status={getStatus(e.group_pictures[0]?.status_pic)}
                  id={e.id}
                  price={e.group_pictures[0]?.price}
                  pictures={arrayOfPictures}
                  description={e.description}
                  picturesIds={pictiresIds}
                  year={e.year}
                  artist={e.artist}
                  size_h={e.size_h}
                  size_w={e.size_w}
                />
              );
            })}
        {loading && (
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#353535"
            ariaLabel="three-dots-loading"
            wrapperClassName="loader-wrapper"
            wrapperStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        )}
      </Pictures>
    </Wraper>
  );
};

export default observer(MyPictures);

const Wraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 30px;
  @media (max-width: 768px) {
    align-items: center;
  }
`;

const AddPic = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
`;
const MainButton = styled.button`
  background-color: #353535;
  color: #f6f6f6;
  padding: 18px 50px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.81px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 4px darkgray;
  }

  &:active {
    transform: scale(0.99);
    box-shadow: 0 6px 4px darkgray;
  }
`;
const MainText = styled.span`
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #b3b3b3;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Pictures = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  @media (max-width: 1000px) {
    gap: 10px;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
const MainPic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 30%;
  @media (max-width: 1000px) {
    width: 30%;
  }
  @media (max-width: 768px) {
    width: 30%;
  }
  @media (max-width: 480px) {
    width: 45%;
  }
  /* @media (max-width: 320px) {
    width: 45%;
  } */
`;
const Img = styled.img`
  width: 100%;
  /* @media (max-width: 1250px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 1000px) {
    width: 180px;
    height: 180px;
  }
  @media (max-width: 480px) {
    width: 140px;
    height: 140px;
  }
  @media (max-width: 320px) {
    width: 100px;
    height: 100px;
  } */
`;
const Title = styled.span`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
`;
const SubTitle = styled.span`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 14.4px;
  color: #b3b3b3;
  ::after {
    content: "";
    text-align: center;
    display: block;
    width: 90px;
    border: 1px solid #b3b3b3;
    margin: auto;
    margin-top: 5px;
  }
  :hover,
  :focus {
    color: #353535;
    ::after {
      content: "";
      border: 1px solid #353535;
    }
  }
`;
