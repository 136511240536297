import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import styled from "styled-components";
import MyPicture from "../../image/Account/MyPicture.png";
import miniBasket from "../../image/Account/miniBasket.svg";
import ModalDelete from "./ModalDelete";
import EditIconSVG from "../../image/Account/edit-major-svgrepo-com.svg";
import { usePersistentStore } from "../../store";
import { useNavigate } from "react-router-dom";

const MyPictureItem = ({
  title,
  status,
  picture,
  id,
  pictures,
  description,
  price,
  picturesIds,
  year,
  artist,
  size_h,
  size_w
}) => {
  const { DetailedArtStore } = usePersistentStore();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const handleGoToEditArt = () => {
    DetailedArtStore.setTitle(title);
    DetailedArtStore.setDescription(description);
    DetailedArtStore.setPrice(price);
    DetailedArtStore.setImages(pictures);
    DetailedArtStore.setPituresIds(picturesIds);
    DetailedArtStore.setYear(year);
    DetailedArtStore.setArtist(artist);
    DetailedArtStore.setSize_h(size_h);
    DetailedArtStore.setSize_w(size_w);
    navigate(`/editArt/${id}`);
  };

  return (
    <>
      <MainPic>
        <EditIcon src={EditIconSVG} onClick={() => handleGoToEditArt()} />
        <BasketItem onClick={() => setOpenModal(!openModal)} src={miniBasket} />
        <Img src={picture} alt="Picture" />
        <Title>{title}</Title>
        <SubTitle>{status}</SubTitle>
      </MainPic>

      {openModal && (
        <ModalDelete
          id={id}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}
    </>
  );
};

export default observer(MyPictureItem);

const BasketItem = styled.img`
  position: absolute;
  width:29px;
  height:29px;
  top: 14px;
  right: 10px;
  transition: all 0.2s ease-in;
  opacity: 0.5;
  background-color: #000;
  padding:1px;
  border-radius:5px;
  &:hover {
    transform: scale(1.01);
    cursor: pointer;
  }
  &:active {
    transform: scale(0.95);
    /* box-shadow: 0 6px 4px darkgray; */
  }
`;

const EditIcon = styled.img`
  position: absolute;
  top: 13px;
  left: 10px;
  transition: all 0.2s ease-in;
  opacity: 0.6;
  width: 20px;
  height: 20px;
  background-color: #000;
  padding:5px;
  border-radius:5px;


  &:hover {
    transform: scale(1.01);
    cursor: pointer;
  }
  &:active {
    transform: scale(0.95);
    /* box-shadow: 0 6px 4px darkgray; */
  }
`;

const MainPic = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* width: 30%;
   */
  width: 350px;
  height: 350px;

  @media (max-width: 1000px) {
    width: 100%;
    height: 450px;
  }
  /* @media (max-width: 320px) {
    width: 45%;
  } */
`;
const Img = styled.img`
  min-width: 100%;
  max-width: 100%;
  height: 220px;
  object-fit: cover;
  @media (max-width: 1000px) {
    max-height: 180px;
  }
  @media (max-width: 600px) {
    max-height: 140px;
  }
  @media (max-width: 600px) {
    max-height: 120px;
  }
`;
const Title = styled.span`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
`;
const SubTitle = styled.span`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 14.4px;
  color: #b3b3b3;
  ::after {
    content: "";
    text-align: center;
    display: block;
    width: 90px;
    border: 1px solid #b3b3b3;
    margin: auto;
    margin-top: 5px;
  }
  :hover,
  :focus {
    color: #353535;
    ::after {
      content: "";
      border: 1px solid #353535;
    }
  }
`;
