import React, { useState } from "react";
import styled from "styled-components";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Container,
} from "@mui/material";
import TechSupportTableBody from "./TechSupportTableBody";
import { observer } from "mobx-react-lite";

const TechSupportTable = () => {
  return (
    <>
      <Wrapper>
        <Container>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead1>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Пользователь</TableCell>
                  <TableCell>Тема обращения</TableCell>
                  <TableCell>Кол-во сообщений</TableCell>
                  <TableCell>Статус</TableCell>
                  <TableCell>
                    Дата создания/ <br />
                    Дата обновления
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead1>
              <TableBody>
                <TechSupportTableBody />
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Wrapper>
    </>
  );
};

export default observer(TechSupportTable);

const TableHead1 = styled(TableHead)``;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Arsenal", sans-serif;
  color: #353535;
  width: 100%;
  margin-left: -24px;
`;
const SpinContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
