import React, { useEffect, useState } from "react";
import "../../Styles/Store.css";
import StoreCard from "./StoreCard.jsx";
import LeftArrow from "../../image/left-arrow.svg";
import Filters from "../Filters";
import Breadcrumbs from "../Breadcrumbs";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { BaseURL } from "../../utils/baseURL";

const Store = () => {
  // const [openFilters, setOpenFilters] = React.useState(false);
  const [pictures, setPictures] = useState([]);

  const array = [1, 1, 1, 1, 1, 1];

  // const toggleFilters = () => {
  //   setOpenFilters(!openFilters);
  // };

  useEffect(() => {
    const getPictures = () => {
      axios
        .get(`${BaseURL}/v1/get_pictures/`)
        .then((res) => {
          setPictures(res.data);
        })
        .catch((err) => {
          console.log("🚀 ~ file: Store.jsx:28 ~ .then ~ err:", err);
        });
    };

    getPictures();
  }, []);

  return (
    <div className="store-wrapper">
      <Breadcrumbs />
      {/* {openFilters && <Filters closeFilters={toggleFilters} />} */}

      <div className="store-title-line">
        <div className="store-title">Оригиналы</div>
        {/* <div
          className="store-filters-container"
          onClick={() => toggleFilters()}
        >
          <img src={LeftArrow} className="store-filter-img" />
          <div className="store-filter-title">Фильтры</div>
        </div> */}
      </div>
      <div className="separate-line" />
      <div className="store-container">
        {pictures?.map((e, i) => (
          <StoreCard
            name={e.title}
            price={e.price}
            firstname={e.add_by.firstname}
            secondname={e.add_by.lastname}
            img={e.image_url}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(Store);
