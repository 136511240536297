import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import Breadcrumbs from "../Breadcrumbs";
import theme_1 from '../../image/theme/theme_1.png'
import theme_2 from '../../image/theme/theme_2.png'
import theme_3 from '../../image/theme/theme_3.png'
import theme_4 from '../../image/theme/theme_4.png'
import ThemesItem from "./ThemesItem";

const Themes = () => {



    return (
        <Wrapper>
            <Container>
                <Breadcrumbs />
                <MainTitle>
                    <Title>Темы</Title>
                </MainTitle>
                <MainTheme>
                    <ThemesItem
                        title={"Деревья"}
                        image={theme_1}
                    />
                    <ThemesItem
                        title={"Лето"}
                        image={theme_2}
                    />
                    <ThemesItem
                        title={"Портреты"}
                        image={theme_3}
                    />
                    <ThemesItem
                        title={"Животные"}
                        image={theme_4}
                    />
                    <ThemesItem
                        title={"Растения"}
                        image={theme_1}
                    />
                    <ThemesItem
                        title={"Море"}
                        image={theme_2}
                    />
                    <ThemesItem
                        title={"Дома"}
                        image={theme_2}
                    />
                    <ThemesItem
                        title={"Русь"}
                        image={theme_3}
                    />
                    
                    
                </MainTheme>
            </Container>
        </Wrapper>
    )
}

export default observer(Themes);

const Wrapper = styled.div`
    font-family: 'Arsenal', sans-serif;
    color: #353535;
    display: flex;
    align-items: center;
    width: 100%;
`;
const Container = styled.div`
    margin: auto;
    max-width: 1170px;
    flex-direction: column;
    @media (max-width: 1210px) {
    width: calc(100% - 40px );
     }
    
`;
const MainTitle = styled.div`
    display: flex;
    border-bottom: 1px solid #B3B3B3;
`;
const Title = styled.span`
    font-size: 80px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 100.32px;
`;
const MainTheme = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 50px 0;
    gap: 30px;
    @media (max-width: 640px) {
        gap: 10px;
        
    }
    
`;



