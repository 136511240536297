import React, { useState } from "react";
import styled from "styled-components";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import { usePersistentStore } from "../../store";
import { useNavigate } from "react-router-dom";

const FormTableItem = () => {
  return (
    <TableRow>
      <TableCell>1</TableCell>
      <TableCell>
        <MainText>
          <Title>Ахмад Мурад Мухрадинов</Title>
          <SubTitle>mahrud@gmail.com</SubTitle>
        </MainText>
      </TableCell>
      <TableCell>Пингвины из Мадагаскара спиздили картину</TableCell>
      <TableCell>35</TableCell>
      <TableCell>В поисках пингвинах</TableCell>

      <TableCell>
        24.05.2027 <br />
        25.05.2028
      </TableCell>
      <TableCell>
        <ButtonBlock>
          <MainButton href="">Открыть чат</MainButton>
          <MainButton href="">Отклонить</MainButton>
        </ButtonBlock>
      </TableCell>
    </TableRow>
  );
};

export default observer(FormTableItem);

const MainText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.span`
  color: #353535;
  font-size: 15px;
`;

const SubTitle = styled.span`
  color: #949494;
  font-size: 15px;
`;

const ButtonBlock = styled.div`
  display: flex;
  gap: 10px;
`;

const MainButton = styled.button`
  background: transparent;
  border: none;
  border: 1px solid #353535;
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
`;
