import React, { useEffect, useState } from "react";
import "../../Styles/ArtistsMini.css";
import ArtistsPhoto from "../../image/artists.png";
import ArtistsMiniCard from "./ArtistsMiniCard";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { BaseURL } from "../../utils/baseURL";
import { ThreeDots } from "react-loader-spinner";

const ArtistsMini = () => {
  const [artists, setArtists] = useState([]);
  const [collectioners, setCollectioners] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getArtists = () => {
      setLoading(true);
      axios
        .get(`${BaseURL}/v1/get_arts/`)
        .then((res) => {
          if (res.data) {
            setArtists(res.data.results);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log("🚀 ~ file: Artists.jsx:22 ~ .then ~ err:", err);
        });
    };

    getArtists();
  }, []);

  return (
    <div className="container-artistsMini">
      <p className="narrowTopText">Художники</p>
      <div className="container-artistsMini-photo">
        {!loading &&
          artists
            ?.filter((e) => e.status === "ART")
            .slice(0, 4)
            .map((e, i) => {
              return (
                <ArtistsMiniCard
                  key={i}
                  image={e.avatar}
                  title={`${e.firstname} ${e.lastname}`}
                />
              );
            })}
        {loading && (
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color="#353535"
            ariaLabel="three-dots-loading"
            wrapperClassName="loader-wrapper"
            wrapperStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          />
        )}
      </div>
      <button className="showMoreBtn" onClick={() => navigate("/artists")}>
        <StyledLink to="/artists">СМОТРЕТЬ БОЛЬШЕ</StyledLink>
      </button>
    </div>
  );
};

export default observer(ArtistsMini);

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
`;
