import React from "react";
import styled from "styled-components";
import Breadcrumbs from "../Breadcrumbs";
import MyPhoto from "../../image/Artist/MyPhoto.png";
import WorksArtistItem from "./WorksArtistItem";
import { observer } from "mobx-react-lite";
import { usePersistentStore } from "../../store";

const WorksArtist = () => {
  const { ArtistStore, UserStore } = usePersistentStore();

  return (
    <Wrapper>
      <Container>
        <Breadcrumbs />
        <MainArtist>
          <ArtistName>{ArtistStore.getName()}</ArtistName>
        </MainArtist>
        <InfoArtist>
          <MainPhoto>
            <Img src={ArtistStore.getAvatar()} alt="Picture" />
          </MainPhoto>
          <MainInfo>
            <Title>О художнике</Title>
            <SubTitle>{ArtistStore.getDescription()}</SubTitle>
          </MainInfo>
        </InfoArtist>
        <Works>
          <TitleWorks>Работы художника</TitleWorks>
          <MyPictures>
            {ArtistStore.getPictures()
              .filter((e) => e.status === "APPROVED")
              .map((e, i) => {
                return (
                  <WorksArtistItem
                    title={e.name}
                    url={e.url}
                    id={e.id}
                    firstname={ArtistStore.getFirstname()}
                    lastname={ArtistStore.getLastname()}
                    pictures={e.pictures}
                    description={e.description}
                    price={e.price}
                    year={e.year}
                    artist={e.artist}
                    size_h = {e.size_h}
                    size_w = {e.size_w}
                  />
                );
              })}
          </MyPictures>
        </Works>
      </Container>
    </Wrapper>
  );
};

export default observer(WorksArtist);

const Wrapper = styled.div`
  font-family: "Arsenal", sans-serif;
  color: #353535;
  display: flex;
  align-items: center;
  width: 100%;
`;
const Container = styled.div`
  margin: auto;
  max-width: 1170px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: calc(100% - 40px);
  }
`;
const MainArtist = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #b3b3b3;
  border-top: 1px solid #b3b3b3;
`;
const ArtistName = styled.span`
  font-size: 32px;
  font-weight: 700;
  line-height: 40.13px;
  padding: 30px 0;
  @media (max-width: 480px) {
    font-size: 26px;
  }
`;
const InfoArtist = styled.div`
  display: flex;
  margin: 50px 0;
  gap: 30px;
  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const MainPhoto = styled.div`
  width: fit-content;
`;
const Img = styled.img`
  width: 370px;
  height: 508px;
  object-fit: cover;

  @media (max-width: 640px) {
    width: 200px;
    height: 270px;
  }
`;
const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 500px;
  @media (max-width: 640px) {
    max-height: 300px;
  }

  @media screen and (max-width: 480px) {
    max-height: unset;
  }
`;
const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 22.57px;
  @media (max-width: 480px) {
    text-align: center;
  }
`;
const SubTitle = styled.span`
  margin: 0;
  font-size: 16px;
  line-height: 30px;

  @media (max-width: 1100px) {
    overflow: auto;
  }
`;
const Works = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 90px;
`;
const TitleWorks = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 30.1px;
  @media (max-width: 480px) {
    text-align: center;
  }
`;
const MyPictures = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
`;
