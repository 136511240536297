import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { usePersistentStore } from '../../store'

const InfoAdd = () => {
  
  const {UserStore} = usePersistentStore();

  return (
    <About>
        <Info>
          <SubTitle>Пол</SubTitle>
          <MyText>{UserStore.getSex()}</MyText>
        </Info>
        <Info>
         <SubTitle>Номер телефона</SubTitle>
        <Social href={`tel:+${UserStore.getPhone()}`}>{UserStore.getPhone()}</Social>
        </Info>
        <Info>
          <SubTitle>Электронная почта</SubTitle>
          <Social href={`mailto:${UserStore.getEmail()}`}>{UserStore.getEmail()}</Social>
        </Info>
        <Info>
          <SubTitle>WhatsApp</SubTitle> 
          <Social href={`tel:+${UserStore.getWhats()}`}>{UserStore.getWhats()}</Social>
        </Info>
        <Info>
          <SubTitle>Telegram</SubTitle> 
          <Social href={`tel:+${UserStore.getTelegram()}`}>{UserStore.getTelegram()}</Social>
        </Info>
      </About>
  )
}

export default observer(InfoAdd);

const About = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
  }
`;
const Info = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;
const Social = styled.a`
  color: #353535;
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
`;
const MyText = styled.span`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
`;
const SubTitle = styled.span `
  font-size: 15px;
  color: #B3B3B3;
  font-weight: 400;
  line-height: 18px;
`;