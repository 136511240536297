import axios from "axios";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import profile from "../../image/Account/profile.png";
import helpIcon from "../../image/Account/help-icon.svg";
import { usePersistentStore } from "../../store";
import { BaseURL } from "../../utils/baseURL";
import HelpModal from "./HelpModal";

const MyInfo = () => {
  const { UserStore } = usePersistentStore();

  const handleLogout = () => {
    UserStore.logOut();
  };

  const [isHelpModal, setIsHelpModal] = useState(false);
  function openHelpModal() {
    setIsHelpModal(true);
  }
  function closeHelpModal() {
    setIsHelpModal(false);
  }

  return (
    <Wrap>
      <MainImage>
        <Img src={UserStore.getAvatar()} alt="Picture" />
      </MainImage>
      <Info>
        <Title>{UserStore.getName()}</Title>
        <SubTitle>{UserStore.getStatus()}</SubTitle>
      </Info>
      <Line></Line>
      <About>
        <Info>
          <SubTitle>Пол</SubTitle>
          <MyText>{UserStore.getSex()}</MyText>
        </Info>
        <Info>
          <SubTitle>Номер телефона</SubTitle>
          <Social href={`tel:+${UserStore.getPhone()}`}>
            {UserStore.getPhone()}
          </Social>
        </Info>
        <Info>
          <SubTitle>Электронная почта</SubTitle>
          <Social href={`mailto:${UserStore.getEmail()}`}>
            {UserStore.getEmail()}
          </Social>
        </Info>
        <Info>
          <SubTitle>WhatsApp</SubTitle>
          <Social href={`tel:+${UserStore.getWhats()}`}>
            {UserStore.getWhats()}
          </Social>
        </Info>
        <Info>
          <SubTitle>Telegram</SubTitle>
          <Social href={`tel:+${UserStore.getTelegram()}`}>
            {UserStore.getTelegram()}
          </Social>
        </Info>
      </About>
      <HelpBlock onClick={openHelpModal}>
        <img src={helpIcon} />
        <div>Помощь</div>
      </HelpBlock>
      <LogOut onClick={() => handleLogout()}>Выйти</LogOut>
      {isHelpModal && <HelpModal closeHelpModal={closeHelpModal} />}
    </Wrap>
  );
};

export default observer(MyInfo);

const HelpBlock = styled.div`
  max-width: 150px;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #353535;
  color: #f6f6f6;
  padding: 10px 0px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.81px;
  border: none;
  transition: all 0.2s ease-in;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 4px darkgray;
  }

  &:active {
    transform: scale(0.99);
    box-shadow: 0 6px 4px darkgray;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 0 30px;
  border-left: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  @media (max-width: 1000px) {
    padding: 0 10px;
  }
  @media (max-width: 768px) {
    border: none;
  }
`;
const MainImage = styled.div``;
const Img = styled.img`
  width: 170px;
  height: 170px;
`;
const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  margin: 0;
`;
const SubTitle = styled.span`
  font-size: 15px;
  color: #b3b3b3;
  font-weight: 400;
  line-height: 18px;
`;
const Line = styled.span`
  border: 1px solid #d9d9d9;
  width: 180px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const About = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;
const Social = styled.a`
  color: #353535;
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
`;
const MyText = styled.span`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
`;
const LogOut = styled.button`
  max-width: 150px;
  min-width: 150px;
  background-color: #353535;
  color: #f6f6f6;
  padding: 10px 0px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.81px;
  border: none;
  margin-bottom: 10px;
  transition: all 0.2s ease-in;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 4px darkgray;
  }

  &:active {
    transform: scale(0.99);
    box-shadow: 0 6px 4px darkgray;
  }
`;
