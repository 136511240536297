import React, { useState } from 'react'
import styled from 'styled-components'
import { AiOutlineClose } from 'react-icons/ai'
import FavorItem from './FavorItem'
import { observer } from 'mobx-react-lite'


const Favourites = ({openFavor, setOpenFavor}) => {

  return (
    <Wrapper onClick={() => setOpenFavor(false)}>
      <Container onClick={e => e.stopPropagation()}>
        <Close>
          <AiOutlineClose onClick={() => setOpenFavor(!openFavor)} size={30}/>
        </Close>
        
        <FavorItem 
          title = {"“Лодка-Водка и Огурчик”"}
          price = {"32 000 Руб."}
        />
         <FavorItem 
          title = {"“Лодка-Водка и Огурчик”"}
          price = {"36 000 Руб."}
        />
         <FavorItem 
          title = {"“Лодка-Водка и Огурчик”"}
          price = {"12 000 Руб."}
        />
        
        
      </Container>
      

      
    </Wrapper>
  )
}

export default observer(Favourites);

const Wrapper = styled.div`
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  font-family: "Arsenal", sans-serif;
  color: #ffffff;
  /* background: rgba(84, 81, 81, 0.8); */
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  @media (max-width: 768px) {
    top: -100px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #353535;
  position: relative;
  height: 100vh;
  gap: 30px;
  padding: 20px 20px;
  @media (max-width: 480px) {
    width: 100%;
    padding: 0;
    padding-top: 20px;
  }
`;
const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  
  @media (max-width: 480px) {
    width: calc(100% - 40px);
  }
`;
