import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import EditProfile from "./EditProfile";
import MyInfo from "./MyInfo";
import MyPictures from "./MyPictures";
import "./Account.css";
import { observer } from "mobx-react-lite";

const Account = () => {
  const location = useLocation();

  return (
    <Container>
      <Wrap>
        <MyInfo />
        <Wraper>
          <Selection>
            <StyledLink
              className={
                location.pathname
                  .toLowerCase()
                  .includes("MyPictures".toLowerCase())
                  ? "Activate"
                  : ""
              }
              to="MyPictures"
            >
              Мои картины
            </StyledLink>

            <NoShow
              className={
                location.pathname
                  .toLowerCase()
                  .includes("techSupport".toLowerCase())
                  ? "Activate"
                  : ""
              }
              to="techSupport"
            >
              Тех. поддержка
            </NoShow>

            <NoShow
              className={
                location.pathname
                  .toLowerCase()
                  .includes("EditProfile".toLowerCase())
                  ? "Activate"
                  : ""
              }
              to="EditProfile"
            >
              Редактировать профиль
            </NoShow>

            <StyledLinkAddTwo
              className={
                location.pathname
                  .toLowerCase()
                  .includes("EditProfile".toLowerCase())
                  ? "Activate"
                  : ""
              }
              to="EditProfile"
            >
              Ред.профиль
            </StyledLinkAddTwo>

            <StyledLinkAdd
              className={
                location.pathname
                  .toLowerCase()
                  .includes("InfoAdd".toLowerCase())
                  ? "Activate"
                  : ""
              }
              to="InfoAdd"
            >
              Инфо
            </StyledLinkAdd>
          </Selection>
          <Outlet />
        </Wraper>
      </Wrap>
    </Container>
  );
};

export default observer(Account);

const Container = styled.div`
  font-family: "Arsenal", sans-serif;
  color: #353535;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const Wrap = styled.div`
  font-family: "Arsenal", sans-serif;
  color: #353535;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  width: calc(100% - 32px);
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 20px;
  }
`;

const Selection = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  gap: 30px;
  @media (max-width: 768px) {
    justify-content: center;
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    padding: 20px 0;
  }
`;

const Wraper = styled.div`
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  width: 60%;
  gap: 20px;
  border-left: 1px solid #d9d9d9;
  padding: 0 30px;
  @media (max-width: 768px) {
    border-left: none;
    width: 100%;
    padding: 0 0;
  }
`;

const StyledLink = styled(Link)`
  font-size: 15px;
  font-weight: 400;
  color: #b3b3b3;
  text-decoration: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 5px;
  transition: all 0.2s ease-in;

  &:hover,
  &:focus {
    border-bottom: 1px solid #353535;
    padding-bottom: 5px;
    color: #353535;
    font-weight: 700;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

const NoShow = styled(StyledLink)`
  @media (max-width: 320px) {
    display: none;
  }
`;

const StyledLinkAdd = styled(Link)`
  display: none;
  @media (max-width: 768px) {
    display: block;
    font-size: 15px;
    font-weight: 400;
    color: #b3b3b3;
    text-decoration: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #b3b3b3;
    padding-bottom: 5px;

    &:hover,
    &:focus {
      border-bottom: 1px solid #353535;
      padding-bottom: 5px;
      color: #353535;
      font-weight: 700;
    }
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;
const StyledLinkAddTwo = styled(Link)`
  display: none;
  @media (max-width: 320px) {
    display: block;
    font-size: 15px;
    font-weight: 400;
    color: #b3b3b3;
    text-decoration: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #b3b3b3;
    padding-bottom: 5px;

    &:hover,
    &:focus {
      border-bottom: 1px solid #353535;
      padding-bottom: 5px;
      color: #353535;
      font-weight: 700;
    }
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;
