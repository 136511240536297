import React from "react";
import "../../Styles/Footer.css";
import footerLogo from "../../image/footerLogo.svg";
import { observer } from "mobx-react-lite";

const Footer = () => {
  return (
    <div className="wrapper-footer">
      <div className="container-footer">
        <img src={footerLogo} alt="Лого" id="footerLogo" />
        <p className="footer-text">
          {new Date().getFullYear()}. Все права защищены
        </p>
      </div>
    </div>
  );
};

export default observer(Footer);
