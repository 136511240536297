import React, { useState } from "react";
import styled from "styled-components";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { AiOutlineClose, AiOutlineCheck } from "react-icons/ai";
import { usePersistentStore } from "../../store";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseURL } from "../../utils/baseURL";


const MuiTableItem = ({
  index,
  firstname,
  lastname,
  date,
  status,
  price,
  picturename,
  pictures,
  description,
  year,
  artist,
  size_h,
  size_w,
}) => {
  const { DetailedArtStore } = usePersistentStore();
  const navigate = useNavigate();
  const [statusState, setStatusState] = useState(status);

  const handleGoToDetailed = () => {
    navigate(`/store/card/considerPicture/id:${index}`);
    DetailedArtStore.setTitle(picturename);
    DetailedArtStore.setDescription(description);
    DetailedArtStore.setAuthor(firstname, lastname);
    DetailedArtStore.setStatus(statusState);

    let arrayOfPictures = pictures.map((e) => e.image_url);

    let picId = pictures.map((e) => e.id);
    DetailedArtStore.setPituresIds(picId)
    DetailedArtStore.setImages(arrayOfPictures);
    DetailedArtStore.setPrice(price);

    DetailedArtStore.setYear(year);
    DetailedArtStore.setArtist(artist);
    DetailedArtStore.setSize_h(size_h);
    DetailedArtStore.setSize_w(size_w);
  };

  const handleApprove = () => {
    const approvingPictures = pictures.map((e) => {
      return e.id;
    });

    const body = {
      pictures: approvingPictures,
    };

    axios
      .post(`${BaseURL}/v1/pictures_confirm/`, body)
      .then((res) => {
        console.log("🚀 ~ file: MuiTableItem.jsx:56 ~ .then ~ res:", res);
        setStatusState("Одобрено");
      })
      .catch((err) => {
        console.log("🚀 ~ file: MuiTableItem.jsx:59 ~ .then ~ err:", err);
      });
  };

  const handleCancel = () => {
    const cancelPictures = pictures.map((e) => {
      return e.id;
    });

    const body = {
      pictures: cancelPictures,
    };

    axios
      .post(`${BaseURL}/v1/pictures_notconfirm/`, body)
      .then((res) => {
        console.log("🚀 ~ file: MuiTableItem.jsx:56 ~ .then ~ res:", res);
        setStatusState("Отклонено");
      })
      .catch((err) => {
        console.log("🚀 ~ file: MuiTableItem.jsx:59 ~ .then ~ err:", err);
      });
  };
  console.log(pictures)

  return (
    <TableRow>
      <TableCell>{index}</TableCell>
      <TableCell>


        <ContainerImg>

          <MainImg>
            <Img src={pictures.at(0)?.image_url || "/"} />
          </MainImg>

          <MiniBlock>
            {pictures.map((elem, index) => (
              <MiniImg src={elem.image_url} />
            ))}
          </MiniBlock>

        </ContainerImg>


      </TableCell>
      <TableCell>{new Date(date).toLocaleDateString()}</TableCell>
      <TableCell>
        {firstname} {lastname}
      </TableCell>
      <TableCell>{statusState}</TableCell>
      <TableCell>{picturename}</TableCell>
      <TableCell>{price} </TableCell>
      <TableCell>
        <CheckBlock>
          {/* {statusState === "На рассмотрении" && ( */}
            <AiOutlineCheck
              size={20}
              color="green"
              onClick={() => handleApprove()}
            />
          {/* )} */}
          {/* {statusState !== "Отклонено" && ( */}
            <AiOutlineClose
              size={20}
              color="red"
              onClick={() => handleCancel()}
            />
          {/* )} */}
        </CheckBlock>
      </TableCell>
      <TableCell>
        <MainButton onClick={() => handleGoToDetailed()} href="">
          Рассмотреть
        </MainButton>
      </TableCell>
    </TableRow>
  );
};

export default observer(MuiTableItem);

const MainButton = styled.button`
  background: transparent;
  border: none;
  border: 1px solid #353535;
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
`;
const CheckBlock = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-around;
`;

const ContainerImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainImg = styled.div`

`;

const Img = styled.img`
  width: 150px;
  height: 150px;
`;

const MiniImg = styled.img`
  width: 44px;
  height: 44px;
`;

const MiniBlock = styled.div`
  display: flex;
  gap: 5px;
`;
