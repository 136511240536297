import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import MuiTableItem from "./MuiTableItem";
import { observer } from "mobx-react-lite";
import { BaseURL } from "../../utils/baseURL";
import axios from "axios";
import { getStatus } from "../../utils/getStatus";
import CircularProgress from "@mui/material/CircularProgress";
import { ThreeDots } from "react-loader-spinner";
// import styled from '@mui/material'

const MuiTable = ({ filterValue }) => {
  const [pictures, setPictures] = useState([]);
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    const getPicturesForApprove = () => {
      setLoading(true);

      axios
        .get(`${BaseURL}/v1/get_arts/`)
        .then((res) => {
          if (res.data) {
            setPictures(res.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("🚀 ~ file: MuiTable.jsx:30 ~ .then ~ err:", err);
        });
    };

    getPicturesForApprove();
  }, []);

  return (
    <Wrapper>
      <Container>
        {loading && (
          <ThreeDots
            height="90"
            width="90"
            radius="9"
            color="#353535"
            ariaLabel="three-dots-loading"
            wrapperClassName="loader-wrapper"
            wrapperStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          />
        )}
        {!loading && (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead1>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>Картины</TableCell>
                  <TableCell>Дата</TableCell>
                  <TableCell>Пользователь</TableCell>
                  <TableCell>Статус</TableCell>
                  <TableCell>Название</TableCell>
                  <TableCell>Цена(Руб)</TableCell>
                  <TableCell>Действие</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead1>
              <TableBody>
                {!!pictures.length &&
                  pictures?.map((e, i) =>
                    e.user_groups
                      .filter((art) =>
                        filterValue
                          ? art.title
                            .toLowerCase()
                            .includes(filterValue.toLowerCase())
                          : art
                      )
                      .map((art) => (
                        <MuiTableItem
                          key={art.id}
                          index={art.id}
                          date={art.group_pictures[0]?.create_at}
                          firstname={e.firstname}
                          lastname={e.lastname}
                          status={getStatus(art.group_pictures[0]?.status_pic)}
                          picturename={art.title}
                          price={art.group_pictures[0]?.price}
                          pictures={art.group_pictures}
                          description={art.description}
                          year={art.year}
                          artist={art.artist}
                          size_h={art.size_h}
                          size_w={art.size_w}
                        />
                      ))
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </Wrapper>
  );
};

export default observer(MuiTable);

const TableHead1 = styled(TableHead)``;

const MainButton = styled.button`
  background: transparent;
  border: none;
  border: 1px solid #353535;
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Arsenal", sans-serif;
  color: #353535;
  width: 100%;
  /* margin: 50px auto; */
`;
const Container = styled.div`
  width: calc(100% - 200px);
  margin: 0 auto;
`;
const SpinContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
