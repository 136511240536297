import React, { useState } from 'react'
import styled from 'styled-components'
import FavorPic from '../../image/Header/FavorPic.png'
import HeartClick from '../../image/Header/HeartClick.svg'
import iconHeart from '../../image/iconHeart.svg'
import { observer } from 'mobx-react-lite'




const FavorItem = ( {title, price} ) => {

  const [clickHeart, setClickHeart] = useState (false);

  return (
    <Picture>
          <MainPicture>
            <Img src={FavorPic} alt="Picture"/>
            <MainInfo>
              <Title>{title}</Title>
              <SubTitle>{price}</SubTitle>
            </MainInfo>
          </MainPicture>
          <HeartBlock>
            <HeartEmprty onClick={() => setClickHeart(!clickHeart)}>
              <Img src={clickHeart? HeartClick : iconHeart} alt="Picture" />
            </HeartEmprty>
          </HeartBlock>
        </Picture>
  )
}

export default observer(FavorItem);

const Picture = styled.div`
  display: flex;
  gap: 40px;
  @media (max-width: 480px) {
    width: calc(100% - 60px);
    justify-content: space-between;
    padding-left: 20px;
  }
`;
const MainPicture = styled.div`
  display: flex;
  gap: 10px;
`;
const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 160px;
`;
const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  @media (max-width: 350px) {
    font-size: 18px;
  }
`;
const SubTitle = styled.span`
  font-size: 15px;
  font-weight: 400;
  line-height: 18.81px;
  color: #B3B3B3;
`;
const Img = styled.img`
  
`;
const HeartBlock = styled.div`
  display: flex;
  position: relative;
`;
const HeartEmprty = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
