import { observer } from 'mobx-react-lite';
import React from 'react'
import styled from 'styled-components';

const PurchaseBlock = ({setOpenPurchase}) => {

  const onPurchaseClick = () => {
    setOpenPurchase(false)
  }

  return (
    <Wrapper onClick={() => setOpenPurchase(false)} >
        <Container onClick={e => e.stopPropagation()}>
          <MainInfo>
            <Title>Оформление заказа</Title>
            <SubTitle><b>Вы успешно оформили заказ! </b><br/> В ближайшее время на Вашу почту придёт ответ от администратора</SubTitle>
            <MainButton onClick = {onPurchaseClick} >Продолжить покупки картин</MainButton>
          </MainInfo>
        </Container>
    </Wrapper>
  )
}

export default observer(PurchaseBlock);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  font-family: "Arsenal", sans-serif;
  color: #ffffff;
  width: 100%;
  z-index:10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
 
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #353535;
  position: relative;
  padding: 35px 30px;
  /* height: 240px;
  width: 380px; */
  gap: 30px;
`;
const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  max-width: 320px;
`;
const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 30.1px;
`;
const SubTitle = styled.span`
  font-size: 15px;
  font-weight: 400;
  line-height: 18.81px;
  text-align: center;
`;
const MainButton = styled.button`
  background: #ffffff;
  color: #353535;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.81px;
  padding: 20px;
  cursor: pointer;
`;

