import axios from "axios";
import { types, flow } from "mobx-state-tree";
import { BaseURL } from "../utils/baseURL";

const UserModel = types.model({
    uuid: types.optional(types.string, ""),
    sex: types.optional(types.string, ""),
    firstname: types.optional(types.string, ""),
    lastname: types.optional(types.string, ""),
    middlename: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    status: types.optional(types.string, ""),
    is_active: types.optional(types.boolean, false),
    avatar: types.optional(types.string, ""),
    phone: types.maybeNull(types.string),
    whatsapp: types.maybeNull(types.string),
    telegram: types.maybeNull(types.string),
    role: types.optional(types.string, ""),
    user: types.optional(types.number, 0),
    email: types.optional(types.string, "")
})

const TokenModel = types.model({
    access: types.string,
    refresh: types.string,
});

const UserStore = types.model('UserStore', {
    Token: TokenModel,
    Role: types.string,
    User: types.maybe(UserModel),
    isAuth: types.optional(types.boolean, false),
    secret: types.optional(types.string, ""),
    email: types.optional(types.string, ""),

})
    .actions((self) => ({
        setEmail(email) {
            self.email = email;
        },
        setToken(payload) {
            self.Token = payload
            self.isAuth = true
        },
        setSecret(secret) {
            self.secret = secret
        },
        getUserData: flow(function* () {
            const config = {
                headers: { Authorization: `Bearer ${self.Token.access}` },
            };
            const result = yield axios.get(`${BaseURL}/v1/hi/`, config);
            if (result.data) {
                self.User = result.data.user_data
            }
        }),
        setFirstname(firstname) {
            self.User.firstname = firstname;
        },
        setSecondname(secondname) {
            self.User.secondname = secondname;
        },
        setSex(sex) {
            self.User.sex = sex;
        },
        setAdmin(role) {
            self.User.role = role;
        },
        setPhone(phone) {
            self.User.phone = phone;
        },
        setWhats(whats) {
            self.User.whatsapp = whats
        },
        setTelegram(telegram) {
            self.User.telegram = telegram;
        },
        setMail(mail) {
            self.User.email = mail
        },
        setDescription(description) {
            self.User.description = description
        },
        setStatus(status) {
            self.User.status = status
        },
        logOut() {
            self.Token.access = "";
            self.Token.refresh = "";
            self.isAuth = false;
            self.Role = "";
        },
        setRole(role) {
            self.Role = role
        }

    }))
    .views((self) => ({
        isAuthFunc() {
            return !!self.Token.access;
        },
        getConfig() {
            return {
                headers: { Authorization: `Bearer ${self.Token.access}` },
            };
        },
        getAvatar() {
            return self.User?.avatar;
        },
        getName() {
            return `${self.User?.firstname} ${self.User?.lastname}`
        },
        getSex() {
            return self.User?.sex === "NONE" ? "Не выбрано" : self.User?.sex === "MALE" ? 'Мужской' : 'Женский'
        },
        getSexValue() {
            return self.User.sex
        },
        getAdmin() {
            return self.User.role;
        },
        getPhone() {
            return self.User?.phone == null ? "" : self.User.phone;
        },
        getWhats() {
            return self.User?.whatsapp;
        },
        getTelegram() {
            return self.User?.telegram == null ? "" : self.User.telegram;
        },
        getEmail() {
            return self.User?.email;
        },
        getConfirmEmail() {
            return self.email
        },
        getFirstname() {
            return self.User.firstname;
        },
        getLastname() {
            return self.User.lastname
        },
        getRole() {
            return self.Role
        },
        getDescription() {
            return self.User?.description;
        },
        getStatus() {
            return self.User?.status === "NONE" ? "Не указано" : self.User?.status === "ART" ? 'Художник' : 'Коллекционер'
        },
        getStatusValue() {
            return self.User.status
        },
        getUUID() {
            return self.User?.uuid
        }
    }));


export default UserStore;
