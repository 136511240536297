import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BackVector from "../../image/Account/BackVector.png";
import { Link, useNavigate } from "react-router-dom";
import picture_3 from "../../image/picture_3.png";
import changePhoto from "../../image/Account/changePhoto.svg";
import changePhotoOne from "../../image/Account/changePhotoOne.png";
import rubleImg from "../../image/Account/ruble-sign-solid-svgrepo-com.svg";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { BaseURL } from "../../utils/baseURL";
import { usePersistentStore } from "../../store";
import { ThreeDots } from "react-loader-spinner";
import Modal from "../Modal/Modal";



const AddPicture = () => {
  const { DetailedArtStore } = usePersistentStore();
  const [files, setFiles] = useState([]);
  const [picturesIds, setPicturesIds] = useState([]);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [theme, setTheme] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [pictures, setPictures] = useState(DetailedArtStore.getImages());
  const [selectedPicture, setSelectedPicture] = useState(
    DetailedArtStore.getImages()[0]
  );
  const [statusState, setStatusState] = useState(DetailedArtStore.getStatus());
  
  console.log(DetailedArtStore.getImages())

  const handleApprove = () => {
    const approvingPictures = pictures.map((e) => {
      return e.id;
    });

    const body = {
      pictures: DetailedArtStore.getPicturesIds(),
    };

    
    

    axios
      .post(`${BaseURL}/v1/pictures_confirm/`, body)
      .then((res) => {
        console.log("🚀 ~ file: MuiTableItem.jsx:56 ~ .then ~ res:", res);
        setStatusState("Одобрено");
        navigate("/admin")
      })
      .catch((err) => {
        console.log("🚀 ~ file: MuiTableItem.jsx:59 ~ .then ~ err:", err);
      });
  };

  return (
    <Container>
      <Wrap>
        <BackAccount>
          
          <StyledLink to="/admin">
            <img src={BackVector} alt="Picture" />
            Вернуться к списку
          </StyledLink>
          <MainArtist>
            <ArtistTitle>Автор картины: <b>{DetailedArtStore.getAuhor()}</b></ArtistTitle>
            <ArtistSubTitle>Статус: <b>{DetailedArtStore.getStatus()}</b></ArtistSubTitle>
          </MainArtist>
        </BackAccount>
        <PictureBlock>
          <PicturePhoto>
            <MainBlockImg
              background={!!selectedPicture ? "none" : "#eaeaea"}
              htmlFor={!!!selectedPicture ? "input_file" : ""}
            >
              <ImgMain
                htmlFor={!!!selectedPicture ? "input_file" : ""}
                src={!!selectedPicture ? selectedPicture : changePhotoOne}
                alt="Picture"
              />
              {!!selectedPicture || (
                <ImgTitle htmlFor={!!!selectedPicture ? "input_file" : ""}>
                  Добавить фото картины
                </ImgTitle>
              )}
            </MainBlockImg>

            <AddPhoto>

            {pictures.map((e, i) => (
              <ContainerPhoto>
              <DownloadedImg
                src={e}
                alt="picture"
                onClick={() => setSelectedPicture(e)}
              />
              
            </ContainerPhoto>
            ))} 
            
              
              
            </AddPhoto>
          </PicturePhoto>
          <PictureInfo>
            <Entry>{DetailedArtStore.getTitle()}</Entry>
            <Description>
              <Info>Описание картины</Info>
              <EntryInfo>{DetailedArtStore.getDescription()}</EntryInfo>
            </Description>
            <Description>
              <Info>Год создания</Info>
              <Entry>{DetailedArtStore.getYear()}</Entry>
            </Description>
            <Description>
              <Info>Автор картины</Info>
              <Entry>{DetailedArtStore.getArtist()}</Entry>
            </Description>
            <BoxSizePicture>
              <Description>
                <Info>Высота</Info>
                <EntrySize>{DetailedArtStore.getSize_h()}</EntrySize>
              </Description>
              <Description>
                <Info>Ширина</Info>
                <EntrySize>{DetailedArtStore.getSize_w()}</EntrySize>
              </Description>
              
            </BoxSizePicture>
            <Check>
              <EntryCostContainer>
                <EntryCost>
                  {DetailedArtStore.getPrice()}

                </EntryCost>
                <RubleImg src={rubleImg} />
              </EntryCostContainer>
              {!loading && (
                <MainButton
                  onClick={() => handleApprove()}
                >
                  Выставить на продажу
                </MainButton>
              )}
              {loading && (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#353535"
                  ariaLabel="three-dots-loading"
                  wrapperClassName="loader-wrapper"
                  wrapperStyle={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                />
              )}
            </Check>
            {err && (
              <Modal
                closeFunction={() => setErr(false)}
                title="Ошибка"
                subtitle="Произошла ошибка при добавлении картины!"
                text="Повторите попытку позже или обратитесь в поддержку."
                buttonText="Закрыть"
                handleOk={() => setErr(false)}
              />
            )}
            {success && (
              <Modal
                closeFunction={() => setSuccess(false)}
                title="Успешно"
                subtitle="Ваша картина добавлена успешно!"
                text="После одобрения администратором, она будет доступна к просмотру всем посетителям сайта"
                buttonText="Продолжить"
                handleOk={() => {
                  setSuccess(false);
                  navigate("/Account/MyPictures");
                }}
              />
            )}
          </PictureInfo>
        </PictureBlock>
      </Wrap>
    </Container>
  );
};

export default observer(AddPicture);

const BoxSizePicture = styled.div`
  display: flex;
  justify-content: space-between;
`;


const MainArtist = styled.div`
  display: flex;
  gap: 20px;
`;

const ArtistTitle = styled.span`
  
`;
const ArtistSubTitle = styled.span`
  
`;
const RubleImg = styled.img`
  position:absolute;
  width:30px;
  height:30px;
  right:5px;
  top:18px;
`;

const EntryCostContainer = styled.div`
  position:relative;
  
`;



const Container = styled.div`
  font-family: "Arsenal", sans-serif;
  color: #353535;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  width: 100%;
  margin-top: 50px;
`;

const Wrap = styled.div`
  font-family: "Arsenal", sans-serif;
  color: #353535;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: calc(100% - 256px); */
  max-width: 1170px;
  gap: 30px;
  margin-bottom: 40px;
  @media (max-width: 1100px) {
    width: calc(100% - 40px);
  }
`;

const BackAccount = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
`;
const Img = styled.img`
  width: 100px;
  height: 100px;
  object-fit: fill;
`;

const DownloadedImg = styled.img`
  width: 100px;
  height: 100px;
  object-fit: fill;
  min-height:100%;
  :hover,
  :focus {
    border: 2px solid #353535;
  }
`;
const MainBlockImg = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 446px;
  height: 400px;
  gap: 22px;
  overflow: hidden;

  /* object-fit: cover; */
  background: ${(props) => props.background};
  @media (max-width: 1000px) {
    width: calc(100% - 40px);
    width: 470px;
    height: 440px;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 300px;
  }
`;

const ImgTitle = styled.label`
  font-size: 15px;
  font-weight: 700;
  line-height: 18.81px;
  color: #353535;
  display: block;
  margin-top: -50px;
`;

const ImgMain = styled.img`
  min-width: 0;
  max-width: 100%;
  min-height: 0;
  max-height: 100%;
  object-fit: cover;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #353535;
  display: flex;
  gap: 5px;
  align-items: center;
`;

const PictureBlock = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PicturePhoto = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 1000px) {
    justify-content: center;
    align-items: center;
  }
`;

const PictureInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  @media (max-width: 1000px) {
    width: 50%;
  }
  @media (max-width: 786px) {
    width: 70%;
  }
  @media (max-width: 400px) {
    width: 90%;
  }
`;

const Entry = styled.div`
  font-family: "Arsenal", sans-serif;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 10px;
  outline: none;
  transition: 0.3s;
  
  :focus {
    border-bottom: 1px solid #353535;
  }
`;

const EntrySize = styled.div`
  font-family: "Arsenal", sans-serif;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 10px;
  outline: none;
  transition: 0.3s;
  width: 150px;
  height: 20px;
  :focus {
    border-bottom: 1px solid #353535;
  }
`;

const EntryInfo = styled.div`
  font-family: "Arsenal", sans-serif;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border: 1px solid #b3b3b3;
  outline: none;
  transition: 0.3s;
  resize: none;
  min-height: 250px;
  :focus {
    border: 1px solid #353535;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Info = styled.label`
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #b3b3b3;
`;

const Check = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const EntryCost = styled.div`
  height:5px;
  font-family: "Arsenal", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 18px;
  border: none;
  border: 1px solid #b3b3b3;
  outline: none;
  transition: 0.3s;
  /* padding: 25px 20px; */
  width: 150px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
	-moz-appearance: textfield;
	-webkit-appearance: textfield;
	appearance: textfield;

 
::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
	display: none;
}
  :focus {
    border: 1px solid #353535;
  }
  @media (max-width: 1000px) {
    max-width: 30%;
  }
`;

const MainButton = styled.button`
  width: fit-content;
  background-color: #353535;
  color: #f6f6f6;
  padding: 25px 10px;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  border: none;
  :hover {
    cursor: pointer;
  }
  @media (max-width: 1000px) {
    width: 70%;
    font-size: 12px;
    padding: 0;
    padding: 17px;
  }
  @media (max-width: 768px) {
    padding: 0;
    padding: 26px;
  }
  @media (max-width: 520px) {
    font-size: 10px;
    padding: 0;
    padding: 25px 0px;
  }
`;

const AddPhoto = styled.form`
  display: flex;
  justify-content: space-around;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const ChangePhoto = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  /* height: 140px; */
  background-color: #eaeaea;
  :hover,
  :focus {
    border: 2px solid #353535;
  }
`;

const AddEntry = styled.input`
  display: none;
`;

const StyledLabel = styled.label``;

const List = styled.select`
  font-family: "Arsenal", sans-serif;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 10px;
  outline: none;
  transition: 0.3s;
  :focus {
    border-bottom: 1px solid #353535;
  }
`;

const ContainerPhoto = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  gap: 10px;

  @media (max-width: 1000px) {
    width: fit-content;
  }
  @media (max-width: 768px) {
    width: fit-content;
  }
  @media (max-width: 560px) {
    width: fit-content;
    align-items: center;
  }
`;
const ButtonDelete = styled.button`
  cursor: pointer;
  color: #353535;
  background: transparent;
  border: none;
  :hover,
  :focus {
    color: #b3b3b3;
  }
`;

const Errors = styled.div`
  color: red;
`;

const Title = styled.span`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  color: #353535;
`;
