import { types } from "mobx-state-tree";
import UserStore from "./UserStore";
import createPersistentStore from "mst-persistent-store";
import ArtistStore from "./ArtistStore";
import { DetailedArtStore } from "./DetailedArtStore";

const RootStore = types.model('RootStore', {
    UserStore: types.optional(UserStore, {
        Token: { access: "", refresh: "" },
        Role: "",
    }),
    ArtistStore: types.optional(ArtistStore, {}),
    DetailedArtStore: types.optional(DetailedArtStore, {})
});

export const [PersistentStoreProvider, usePersistentStore] =
    createPersistentStore(RootStore, {}, {}, { writeDelay: 100, logging: false });