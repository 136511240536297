import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import "swiper/swiper.min.css";
import picture from "../../image/picture.png";
import Slider from "./Slider";
import { BaseURL } from "../../utils/baseURL";
import { usePersistentStore } from "../../store";
import UserStore from "../../store/UserStore";
import { observer } from "mobx-react-lite";
import { emailRegExp } from "../../utils/emailRegExp";
import { ThreeDots } from "react-loader-spinner";

const MainLogin = () => {
  const { UserStore } = usePersistentStore();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const registOn = (e) => {
    setLoading(true);
    e.preventDefault();
    const newLog = {
      username,
      password,
    };
    // console.log (newLog)

    if (!!username && !!password && emailRegExp.test(username)) {
      const body = {
        username: username,
        password: password,
      };

      axios
        .post(`${BaseURL}/token/`, body)
        .then((res) => {
          UserStore.setAdmin("")
          UserStore.setToken(res.data);
          UserStore.getUserData();
          if (UserStore.getAdmin() === "ADMIN") {
            UserStore.setRole("admin")
            navigate("/admin")
          }
          else if (UserStore.getAdmin() === "MEMBER") {
            navigate("/Account/MyPictures");
          }
          
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setError(true);
        });
    } else {
      setError(true);
      setLoading(false);
    }
  };

  return (
    <Wraper>
      <Container>
        <MainImage>
          {/* <Img src={picture} alt="Picture"/> */}
          <Slider />
        </MainImage>
        <Login action="#" method="get">
          <Title>Войти</Title>
          <SubTitle>
            Нет аккаунта? <StyledLink to="/Register">Создайте его</StyledLink>{" "}
          </SubTitle>
          <Board>
            <Entry
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                setError(false);
              }}
              type="text"
              name="username"
              placeholder="Адрес электронной почты"
            />
            {/* {(error) && <Errors>Неккоректные данные</Errors>} */}
            <Entry
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(false);
              }}
              type="password"
              name="password"
              placeholder="Пароль"
            />
            {error && <Errors>Некорректные данные</Errors>}
          </Board>
          {!loading && (
            <LoginButton
              disabled={!!!username || !!!password}
              onClick={(e) => registOn(e)}
            >
              Продолжить
            </LoginButton>
          )}
          {loading && (
            <ThreeDots
              height="40"
              width="40"
              radius="9"
              color="#353535"
              ariaLabel="three-dots-loading"
              wrapperClassName="loader-wrapper"
              wrapperStyle={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            />
          )}
          <RecoveryButton to='/emailConfirm'>Восстановить аккаунт</RecoveryButton>
        </Login>
      </Container>
    </Wraper>
  );
};

export default observer(MainLogin);

const RecoveryButton = styled(Link)`
  /* background-color: #353535; */
  text-decoration: none;
  color: #538DAD;
  background: transparent;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  width: calc(100% - 200px);
  transition: all 0.2s ease-in;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
    /* box-shadow: 0 6px 4px darkgray; */
  }

  &:active {
    transform: scale(0.99);
    /* box-shadow: 0 6px 4px darkgray; */
  }
`;
const Wraper = styled.div`
  font-family: "Arsenal", sans-serif;
  color: #353535;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
`;

const MainImage = styled.div`
  width: 60%;
  max-height: 100vh;

  @media (max-width: 1000px) {
    display: flex;
    height: 100vh;
    width: 100%;
  }

  @media (max-width: 480px) {
    display: none;
  }
`;

const Img = styled.img`
  width: 100%;
  max-height: 100vh;
  object-fit: cover;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 1000px) {
    justify-content: center;
    position: relative;
    min-height: 100vh;
  }
`;

const Login = styled.form`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 1000px) {
    width: 60%;
    position: absolute;
    z-index: 100;
    background-color: #fff;
    padding: 50px 0;
  }

  @media (max-width: 768px) {
    width: 60%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 45.14px;
  margin: 0;

  /* margin-bottom: 20px; */
`;

const SubTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20.06px;
  /* margin-bottom: 84px; */
`;

const StyledLink = styled(Link)`
  font-weight: 700;
  color: #353535;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1px solid #353535;
  padding-bottom: 5px;
`;

const Board = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  width: 100%;
`;

const Entry = styled.input`
  font-family: "Arsenal", sans-serif;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #b3b3b3;
  width: calc(100% - 200px);
  /* margin-bottom: 80px; */
  outline: none;
  transition: 0.3s;
  font-size: 16px;

  :focus {
    border-bottom: 1px solid #353535;
  }

  @media (max-width: 1000px) {
    width: calc(100% - 70px);
  }
`;

const LoginButton = styled.button`
  background-color: #353535;
  border: none;
  color: #f6f6f6;
  padding: 25px 0;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.81px;
  width: calc(100% - 200px);
  transition: all 0.2s ease-in;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 4px darkgray;
  }

  &:active {
    transform: scale(0.99);
    box-shadow: 0 6px 4px darkgray;
  }

  @media (max-width: 1000px) {
    width: calc(100% - 70px);
  }
`;

const Errors = styled.div`
  color: red;
`;
