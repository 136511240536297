import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { usePersistentStore } from "../../store";
import axios from "axios";
import { BaseURL } from "../../utils/baseURL";
import { ThreeDots } from "react-loader-spinner";
import Modal from "../Modal/Modal";

const EditProfile = () => {
  const { UserStore } = usePersistentStore();
  const [sex, setSex] = useState(UserStore.getSexValue());
  const [firstname, setFirstname] = useState(UserStore.getFirstname());
  const [lastname, setLastname] = useState(UserStore.getLastname());
  const [phone, setPhone] = useState(UserStore.getPhone());
  const [email, setEmail] = useState(UserStore.getEmail());
  const [telegram, setTelegram] = useState(UserStore.getTelegram());
  const [whatsapp, setWhatsapp] = useState(UserStore.getWhats());
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(UserStore.getDescription());
  const [status, setStatus] = useState(UserStore.getStatusValue());
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(false);

  const handleUpdate = () => {
    setLoading(true);
    setSuccess(false);
    setErr(false);
    const body = {
      firstname: firstname,
      lastname: lastname,
      sex: sex,
      phone: phone,
      email: email,
      telegram: telegram,
      whatsapp: whatsapp,
      description: description,
      // status: status,
    };

    axios
      .post(`${BaseURL}/v1/change_profile/`, body, UserStore.getConfig())
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        if (res.data.result) {
          UserStore.setFirstname(firstname);
          UserStore.setSecondname(lastname);
          UserStore.setPhone(phone);
          UserStore.setEmail(email);
          UserStore.setTelegram(telegram);
          UserStore.setWhats(whatsapp);
          UserStore.setSex(sex);
          UserStore.setDescription(description);
          // UserStore.setStatus(status);
        }
      })
      .catch((err) => {
        setErr(true);
        setLoading(false);
        console.log("🚀 ~ file: EditProfile.jsx:33 ~ .then ~ err:", err);
      });
  };

  return (
    <Wraper>
      <MainInfo>
        <Title>Основная информация</Title>
      </MainInfo>
      <Board>
        <InputContainer>
          <Title>Имя</Title>
          <Entry
            type="text"
            name="username"
            placeholder="Имя"
            onChange={(e) => setFirstname(e.target.value)}
            value={firstname}
          />
        </InputContainer>
        <InputContainer>
          <Title>Фамилия</Title>
          <Entry
            type="text"
            name="surname"
            placeholder="Фамилия"
            onChange={(e) => setLastname(e.target.value)}
            value={lastname}
          />
        </InputContainer>
        {/* <Entry 
        type="text" 
        name='kind' 
        placeholder='Пол'
        /> */}
        {/* <InputContainer>
          <Title>Пол</Title>
          <List
            name="sex"
            onChange={(e) => setSex(e.target.value)}
            defaultValue={sex}
          >
            <ListOption value="None">Не указано</ListOption>
            <ListOption value="MALE">Мужской</ListOption>
            <ListOption value="FEMALE">Женский</ListOption>
          </List>
        </InputContainer> */}
        <InputContainer>
          <Title>Адрес электронной почты</Title>
          <Entry
            type="email"
            name="email"
            placeholder="Адрес электронной почты"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </InputContainer>
        <InputContainer>
          <Title>Профиль</Title>
          <List
            name="status"
            onChange={(e) => setStatus(e.target.value)}
            defaultValue={status}
          >
            <ListOption value="None">Не указано</ListOption>
            <ListOption value="ART">Художник</ListOption>
            <ListOption value="COLLECT">Коллекционер</ListOption>
          </List>
        </InputContainer>
        <InputContainer>
          <Title>Номер телефона</Title>
          <Entry
            type="text"
            name="username"
            placeholder="Номер телефона"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
        </InputContainer>
        <InputContainer>
          <Title>WhatsApp</Title>
          <Entry
            type="text"
            name="WhatsApp"
            placeholder="WhatsApp"
            onChange={(e) => setWhatsapp(e.target.value)}
            value={whatsapp}
          />
        </InputContainer>
        <InputContainer>
          <Title>Telegram</Title>
          <Entry
            type="tel"
            name="Telegram"
            placeholder="Telegram"
            onChange={(e) => setTelegram(e.target.value)}
            value={telegram}
          />
        </InputContainer>
        <InputContainer>
          <Title>О себе</Title>
          <About
            type="text"
            name="description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            placeholder="О себе"
          />
        </InputContainer>
      </Board>
      {!loading && (
        <MainButton
          onClick={() => handleUpdate()}
          // disabled={
          //   !!!firstname ||
          //   !!!lastname ||
          //   !!!sex ||
          //   !!!email ||
          //   !!!phone ||
          //   !!!whatsapp ||
          //   !!!telegram ||
          //   !!!description ||
          //   !!!status
          // }
        >
          Сохранить
        </MainButton>
      )}
      {loading && (
        <ThreeDots
          height="40"
          width="40"
          radius="9"
          color="#353535"
          ariaLabel="three-dots-loading"
        />
      )}
      {/* {err && <Title>Произошла ошибка, повторите попытку позже</Title>} */}
      {err && (
        <Modal
          closeFunction={() => setErr(false)}
          title="Ошибка"
          subtitle="Произошла ошибка при обновлении данных!"
          text="Повторите попытку позже или обратитесь в поддержку."
          handleOk={() => setErr(false)}
          buttonText="Закрыть"
        />
      )}
      {success && (
        <Modal
          closeFunction={() => setSuccess(false)}
          title="Обновлено"
          subtitle="Ваши данные обновлены!"
          text=""
          handleOk={() => setSuccess(false)}
          buttonText="Продолжить"
        />
      )}
    </Wraper>
  );
};

export default observer(EditProfile);

const Wraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 35px;
  min-height: 100vh;
  @media (max-width: 480px) {
    align-items: center;
  }
`;

const MainInfo = styled.div``;

const MainButton = styled.button`
  background-color: #353535;
  color: #f6f6f6;
  padding: 21px 76px;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.81px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 6px 4px darkgray;
  }

  &:active {
    transform: scale(0.99);
    box-shadow: 0 6px 4px darkgray;
  }
`;

const Title = styled.span`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  color: #353535;
`;

const Img = styled.img``;

const Board = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  @media (max-width: 1000px) {
    gap: 30px;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

const Entry = styled.input`
  font-family: "Arsenal", sans-serif;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #b3b3b3;
  outline: none;
  transition: 0.3s;
  font-size: 16px;
  width: 350px;
  height: 20px;

  &:focus {
    border-bottom: 1px solid #353535;
  }

  @media (max-width: 480px) {
    width: 90%;
  }
`;

const List = styled.select`
  width: 350px;
  font-family: "Arsenal", sans-serif;
  font-size: 15px;
  font-weight: 400;
  border: none;
  border-bottom: 1px solid #b3b3b3;
  outline: none;
  transition: 0.3s;
  &:focus {
    border-bottom: 1px solid #353535;
  }

  @media (max-width: 480px) {
    width: 90%;
  }
`;

const ListOption = styled.option`
  width: 100% !important;
  @media (max-width: 1280px) {
    width: 200px !important;
  }
  @media (max-width: 480px) {
    width: 50px !important;
  }
`;

const About = styled.textarea`
  font-family: "Arsenal", sans-serif;
  font-size: 16px;
  border: 1px solid #b3b3b3;
  outline: none;
  transition: 0.3s;
  font-size: 16px;

  width: 350px;

  height: 100px;

  resize: none;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: fit-content;

  @media (max-width: 480px) {
    width: 100%;
  }
`;
