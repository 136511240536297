import React, { useState } from "react";
import styled from "styled-components";
import AdminPic from "../../image/AdminImage/AdminPic.svg";
import Logout from "../../image/AdminImage/Logout.svg";

import AdminAvatar from "../../image/AdminImage/AdminAvatar.png";
import MuiTable from "./MuiTable";
import { observer } from "mobx-react-lite";
import { usePersistentStore } from "../../store";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./Admin.css"

const Admin = () => {
  const [search, setSearch] = useState("");
  const { UserStore } = usePersistentStore();
  const navigate = useNavigate();

  const location = useLocation();

  const handleLogout = () => {
    UserStore.logOut();
    navigate("/MainLogin");
  };

  return (
    <Wrapper>
      <Container>
        <MainHead>
          <MainBlock>
            <Pictures
              className={
                location.pathname
                  .toLowerCase()
                  .includes("adminPage".toLowerCase())
                  ? "Activate-admin"
                  : ""
              }
              to='/admin/adminPage'
            >
              <Img src={AdminPic} alt="Picture" />
              <MainText>Картины на одобрении</MainText>
            </Pictures>
            <Pictures
              className={
                location.pathname
                  .toLowerCase()
                  .includes("adminForm".toLowerCase())
                  ? "Activate-admin"
                  : ""
              }
              to='/admin/adminForm'
            >
              <Img src="https://res.cloudinary.com/dmxoqnqsu/image/upload/v1693213485/Document_2_zjf2hz.svg" alt="Picture" />
              <MainText>Заявки</MainText>
            </Pictures>
          </MainBlock>

          <MainAdmin>
            <Img src={AdminAvatar} alt="Picture" />
            <MainText>oldi.dev@gmail.com</MainText>
            <MainButton onClick={() => handleLogout()}>
              <Img src={Logout} alt="Picture" />
              
            </MainButton>
          </MainAdmin>
        </MainHead>

        <Outlet />
      </Container>
    </Wrapper>
  );
};

export default observer(Admin);

const MainBlock = styled.div`
  display: flex;
  gap: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Arsenal", sans-serif;
  color: #353535;
  min-height: 100vh;
  width: 100%;
  /* margin: 50px auto; */
`;
const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  margin-bottom: 20px;
`;
const MainHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  border-bottom: 1px solid #d9d9d9;
`;

const Pictures = styled(Link)`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 30px 0;
  text-decoration: none;
  color: #353535;
  cursor: pointer;
  &:hover, &:active, &:focus{
    border-bottom: 1px solid #353535;
  }
  
`;
const Img = styled.img``;
const MainButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
`;
const MainText = styled.span`
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
`;
const MainAdmin = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
