import React from "react";
import "../../Styles/CollectionersMini.css";
import Collectioner from "../../image/collectioner.png";
import { observer } from "mobx-react-lite";

const CollectionersCard = ({
  firstname,
  lastname,
  description,
  pictures,
  avatar,
}) => {
  return (
    <div className="collectionersMini-card">
      <img src={avatar} alt="" />

      <div className="collectionersMini-card-sub">
        <p>
          {firstname} {lastname}
        </p>
        <p id="ten-arts">{pictures.length} картин</p>
      </div>
    </div>
  );
};

export default observer(CollectionersCard);
