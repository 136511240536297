import { types } from "mobx-state-tree";

const PictureModel = types.model({
    url: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    pictures: types.optional(types.array(types.string), []),
    id: types.optional(types.number, -1),
    description: types.optional(types.string, ""),
    price: types.optional(types.number, 0),
    status: types.optional(types.string, ""),
    year: types.maybeNull(types.string),
    artist: types.maybeNull(types.string),
    size_h: types.maybeNull(types.string),
    size_w: types.maybeNull(types.string),
})


const ArtistStore = types.model({
    firstname: types.optional(types.string, ""),
    lastname: types.optional(types.string, ""),
    avatar: types.optional(types.string, ""),
    pictures: types.optional(types.array(PictureModel), []),
    description: types.optional(types.maybeNull(types.string), null)
}).actions((self) => ({
    setFirstname(firstname) {
        self.firstname = firstname;
    },
    setLastname(lastname) {
        self.lastname = lastname;
    },
    setAvatar(avatar) {
        self.avatar = avatar;
    },
    setPictures(pictures) {
        self.pictures = pictures;
    },
    setDescription(description) {
        self.description = description
    }
})).views((self) => ({
    getName() {
        return `${self.firstname} ${self.lastname}`
    },
    getAvatar() {
        return self.avatar
    },
    getPictures() {
        return self.pictures
    },
    getFirstname() {
        return self.firstname
    },
    getLastname() {
        return self.lastname
    },
    getDescription() {
        return self.description
    }
}))

export default ArtistStore