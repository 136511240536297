import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import picture from '../../image/picture.png'
import picture_2 from '../../image/picture_2.png'
import picture_3 from '../../image/picture_3.png'
import picture_4 from '../../image/picture_4.png'
import styled from 'styled-components'
import {  A11y, Autoplay } from 'swiper';
import { observer } from 'mobx-react-lite';

const Slider = () => {
  return (
    <Swiper
      modules={[  A11y, Autoplay]}
      spaceBetween={0}
      slidesPerView={1}
      // autoplay = {true}
      
      
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide><Img src={picture} alt="Picture"/> </SwiperSlide>
      <SwiperSlide><Img src={picture_2} alt="Picture"/> </SwiperSlide>
      <SwiperSlide><Img src={picture_3} alt="Picture"/> </SwiperSlide>
      <SwiperSlide><Img src={picture_4} alt="Picture"/> </SwiperSlide>
    </Swiper>
  )
}

export default observer(Slider);

const Img = styled.img`
  width: 100%;
  max-height: 100vh;
  object-fit: cover;
  
  

`;