import React, { useEffect, useState } from "react";
import "../../Styles/TopWeek.css";
import topWeekPhoto from "../../image/topWeek.png";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { BaseURL } from "../../utils/baseURL";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const TopWeek = () => {
  const [topWeekPictures, setTopWeekPictures] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/artists");
  };

  useEffect(() => {
    const getArtists = () => {
      setLoading(true);
      axios
        .get(`${BaseURL}/v1/get_arts/`)
        .then((res) => {
          //   console.log("🚀 ~ file: Artists.jsx:18 ~ .then ~ res:", res);
          if (res.data) {
            // console.log(res.data)
            let newArr = [];
            res.data.results.forEach((artist) => {
              artist.user_groups.forEach((picture) => {
                if (
                  picture?.group_pictures[0]?.image_url &&
                  picture?.group_pictures[0]?.status_pic === "APPROVED"
                ) {
                  newArr.push(picture.group_pictures[0]?.image_url);
                }
              });
            });
            setTopWeekPictures(newArr);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log("🚀 ~ file: Artists.jsx:22 ~ .then ~ err:", err);
        });
    };

    getArtists();
  }, []);

  return (
    <div className="container-topweek">
      <div className="topweek-text">
        <p className="narrowTopText">
          ТОП <br />
          НЕДЕЛИ
        </p>
        <div className="topweek-photo">
          {!loading &&
            topWeekPictures
              ?.filter((e) => e.includes("7"))
              .splice(0, 3)
              .map((e, i) => <img src={e} key={i} />)}
          {loading && (
            <ThreeDots
              height="40"
              width="40"
              radius="9"
              color="#353535"
              ariaLabel="three-dots-loading"
              wrapperClassName="loader-wrapper"
              wrapperStyle={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            />
          )}
        </div>
        <button className="showMoreBtn" onClick={() => handleClick()}>
          СМОТРЕТЬ БОЛЬШЕ
        </button>
      </div>
    </div>
  );
};

export default observer(TopWeek);
